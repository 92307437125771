<template>
   <!-- Container principal da tela de login -->
   <v-container 
         fluid
         v-if="mostra_login && !$store.state.usuario.logado"
         class="pa-0 ma-0"
   >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo da tela de login -->
          <v-card
            class="rounded-0"
          >
            <v-img
              min-height="600px"
              max-height="920px"
              src="img/tela_fundo_1.jpg"
            >
              <!-- Dentro da imagem existe um outro container para centralizar o card que contém o formulário de login -->
              <v-container 
                    fluid
                    fill-height

              >
                <!-- Essa v-row centraliza o card do formulário na vertical --> 
                <v-row
                  justify="center"
                >
                  <!-- Para telas pequenas usa toda a largura da tela e para telas medias usa somente sete colunas --> 
                  <v-col cols="12" md="7" >

                    <!-- Esse é o card branco que aparece no meio da tela --> 
                    <v-card
                      class="mx-auto rounded-xl"
                      elevation="12"
                    >
                      <v-row
                        justify="center"
                      >
                        <!-- O card branco tem duas colunas: uma com um card azul e outra com o form de login --> 
                        <v-col cols="12" md="6" class="d-none d-md-block ">
                          <v-container fill-height fluid>

                            <!-- Esse card azul --> 
                            <v-card 
                               dark 
                               class="primary mx-auto rounded-xl " 
                               min-height="360px"    
                               flat
                               
                            >
                              <v-card-title>Área do Participante</v-card-title>
                              <v-card-text>
                                <br>
                                Caso não tenha acesso, clique em <router-link class="v-btn white--text" to="/primeiroacesso">Primeiro Acesso</router-link>
                                <br>
                                Caso tenha esquecido a senha, clique em <router-link class="v-btn white--text" to="/esquecisenha">Esqueci minha Senha</router-link></a>
                                <br>
                                Caso seu e-mail esteja desatualizado no cadastro clique em <router-link class="v-btn white--text" to="/alteraremail">Alterar Email</router-link>
                              </v-card-text>
                          
                            </v-card>
                          </v-container>   
                        </v-col>

                        <!-- Essa é a segunda coluna -->
                        <v-col cols="12" md="6">
                          <v-card-text>

                            <!-- Esse form de login -->
                            <v-form 
                              @submit.prevent="Entrar" 
                              ref="form"
                              v-model="valid"
                              lazy-validation
                            >
                              <v-img
                                  alt="Serpros Fundo Multipatrocinado"
                                  class="shrink"
                                  contain
                                  src="img/serpros-logo.png"
                                  height="60"
                              />

                              <br>

                              <v-text-field
                                  v-model="login.cpf"
                                  label="CPF"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                              ></v-text-field>

                              <v-text-field
                                  v-model="login.senha"
                                  label="Senha"
                                  type="password"
                                  outlined
                                  rounded
                                  :rules="Rules.Required"
                                  :append-icon="exibe_senha ? 'mdi-eye' : 'mdi-eye-off'"
                                  @input="exibe_senha=false"
                                  @click:append="() => (exibe_senha = !exibe_senha)"
                                  :type="exibe_senha ? 'text' : 'password'"
                              ></v-text-field>
                              
                              <div class="text-center">
                                  <v-btn type="submit"  color="primary" :disabled="!valid" rounded :loading="loading"> Entrar </v-btn>
                              </div>
                              
                              <br>

                              <v-alert dense v-if="mostra_mensagem" v-model="mostra_mensagem"  text type="error"> {{mensagem}} </v-alert>

            

                              <v-footer
                                padless
                                class="d-block d-md-none"
                              >
                                <v-card
                                  flat
                                  tile
                                  width="100%"
                                  class="text-center"
                                >

                                  <v-card-text>
                                  <router-link class="v-btn white--text" to="/primeiroacesso"><v-btn plain >Primeiro Acesso</v-btn></router-link>
                                  <router-link class="v-btn white--text" to="/esquecisenha"><v-btn plain >Esqueci Senha</v-btn></router-link> 
                                  <router-link class="v-btn white--text" to="/alteraremail"><v-btn plain >Alterar e-mail</v-btn></router-link>  
                                    
                                  </v-card-text>

                                </v-card>
                              </v-footer>

                            </v-form>
                          </v-card-text>
                        </v-col>
                      </v-row>    
                    </v-card>
                  </v-col>
                </v-row> 
              </v-container>   
            </v-img>
         <!--   <v-footer
              padless
              class="d-md-none"
            >
              <v-card
                flat
                tile
                width="100%"
                height="200px"
                class="grey darken-4 text-center"
              >

                <v-card-text class="white--text">
                  {{ new Date().getFullYear() }} — <strong>Serpros Fundo Multipatrocinado</strong>
                </v-card-text>
              </v-card>
            </v-footer> -->
          </v-card>  
        </v-col>
      </v-row>  
   </v-container>

   
</template>

<script>
  export default {
    data: function(){
      return {  
          active: null,
          indeterminate: null,
          loading: false,
          valid: false,
          Rules:{
            Required:[v => !!v || 'Obrigatório!'],   
          },
          login:{
            cpf:null, 
            senha:null
          },
          mensagem: '',
          mostra_mensagem: false,
          mostra_login: false,
          code: null,
          usuario: {
                logado: false,
              },
          id_pessoa: null,
          exibe_senha: false
      }
    },

    created: function () {

      this.usuario = {logado: false};
      this.$store.commit('setusuariologado', this.usuario); 
      this.$store.commit('setatendente', null);
      this.$store.commit('setpessoafisica',null);
      this.$store.commit('setsituacao',null);
      this.$store.commit('setidpessoa',null);
      this.$store.commit('setidemp',null);
      this.$store.commit('setplanosusuario', []);
      this.$store.commit('setplanoscontracheque', []);
      
      var texto ='<p style="color:white; font-size: 14px;">Digite seu CPF, sua senha e clique em <b>Entrar</b>.</p>'+
                 '<p style="color:white; font-size: 14px;">Caso não tenhalique em <b>Entrar</b>.</p>'
      this.$store.dispatch('exibeajuda',texto);

      if(this.$route.params.code != null){
        this.code = this.$route.params.code;
        this.EntrarComCodigo();
      }else{
         this.Carregar();
      }

    },


    methods:{
      
      Carregar(){
        
        this.mostra_login = true;

      },

      Entrar() {

        if( this.$refs.form.validate() ){
          this.mostra_mensagem = false;
          this.loading = true;

          let params = {
            cpf: this.login.cpf.replace(/[^\d]+/g,''),
            senha: this.login.senha
          }

          this.$API.post( '/api/autenticar', params)
          .then(response => {

            this.$API.Iniciar(response.data.token);
            //this.$APIpartic.Iniciar(response.data.tokenpartic);
            this.id_pessoa = response.data.id_pessoa;
            this.$store.dispatch('exibeajuda','');
            this.iniciarSessao(); 
                          
          }) 
          .catch(error => {
            console.log(error.response.data.error_description);
            this.mensagem = error.response.data.error_description;
            this.loading = false;
            this.mostra_mensagem = true;
            
          });  

        }

      },


      iniciarSessao(){

        var params = {
            id_pessoa: this.id_pessoa
        }
        
        this.$API.post( '/api/iniciarsessao', params)
        .then(response => {

          if(response.data.codigo == 'ok'){
            
            this.usuario = {
              logado: true,
            };

            this.$store.commit('setplanos', response.data.planos);
            this.$store.commit('setusuariologado', this.usuario);
            this.$store.commit('setpessoafisica',response.data.pessoa_fisica);
            this.$store.commit('setsituacao',response.data.situacao);
            this.$store.commit('setalerta','');
            this.$store.commit('setidpessoa',this.id_pessoa);
            this.$store.commit('setidemp',response.data.id_emp);
            this.$store.commit('setplanosusuario', response.data.planos_usuario);
            this.$store.commit('setplanoscontracheque', response.data.planos_contracheque);
            this.$store.commit('settaxaemprestimo', response.data.taxa_emprestimo);
            this.$store.commit('setadimplente_rs', response.data.adimplente_rs);
            this.$store.commit('setin1343', response.data.tem_in1343);
            this.$store.commit('setconsentimento', response.data.consentimento_lgpd);
            
            this.carregaTabelas();

          }else{
            this.loading = false;
            this.mostra_login = true;
          }
            
        }) 
        .catch(error => {
            console.log(error.response.data);
            this.loading = false;
            this.mostra_login = true;
        });  
      },


      carregaTabelas(){

        this.$API.get('/api/tabelas')
        .then(response => {
             
            
            this.$store.commit('setbeneficios', response.data.beneficios);
            this.$store.commit('setbancosemprestimo', response.data.bancos);
            this.$store.dispatch('iniciamenu', response.data.menu);
            
            this.mostra_login = false; 
            this.$router.push('/');
            
        });
      },


      EntrarComCodigo() {

          this.mostra_mensagem = false;
          this.loading = true;

          let params = {
            grant_type: 'authorization_code',
            client_id: process.env.VUE_APP_CLIENTID,
            code: this.code,
            somente_leitura: 'N'
          }

          this.$API.post( '/api/aut/tokencomcodigo', params)
          .then(response => {

            this.$API.Iniciar(response.data.token);
            this.id_pessoa = response.data.id_pessoa;
            this.$store.dispatch('exibeajuda','');
            this.iniciarSessao(); 
                          
          }) 
          .catch(error => {
            console.log(error.response.data.error_description);
            this.mensagem = error.response.data.error_description;
            this.loading = false;
            this.mostra_mensagem = true;
            
          });  

        

      },

    }
    
     
  }
</script>