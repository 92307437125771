<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
              <v-form 
                ref="form"
                lazy-validation
              >
              
                <v-row v-if="!loading && !exibeDocumento">
                  <v-col cols="12" md="3" >
                    <v-card-text>     
                      <v-select
                        v-model="ano"
                        outlined
                        dense
                        :items="anos"
                        :rules="Rules.Required"
                        placeholder="Ano"
                      >
                      </v-select>
                    </v-card-text>
                  </v-col>
                </v-row>   
              </v-form>


              <v-row v-if="!loading && !exibeDocumento">
                <v-col cols="12"  >
                  <v-card-text>  
                    <v-btn color="primary" rounded dark @click="informe" >Visualizar</v-btn>
                  </v-card-text>
                </v-col>
              </v-row>  


              <v-row v-if="!loading && naoEncontrato">
                <v-col cols="12" >
                  <v-card-text >
                    <div class="text-justify text-h5 font-weight-light red--text text--darken-2"> {{ mensagem }} </div>
                  </v-card-text>  
                </v-col>
              </v-row> 


              <v-row v-if="!loading && exibeDocumento">
                <v-col cols="12" md="12" class="text-right">

                   <v-card-text >
                    <v-btn color="primary" rounded dark @click="exibeDocumento=false;fileURL=null;" >Voltar</v-btn>
                  </v-card-text>
                  
                </v-col>
              </v-row> 


              <v-row v-if="!loading && exibeDocumento" class="fill-height mt-n5" >
                <v-col cols="12" md="12" >
                    <v-card-text style="height:98%">     
                      <iframe :src="'data:Application/pdf;base64,'+ fileURL" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>
                    </v-card-text>
                </v-col>
                
              </v-row> 


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo: "Informe para IRPF",
            digitar_valor: false,
            form_valido: true,
            loading: true,
            loading2: true,
            loading_fator: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            fileURL: null,
            ano: null,
            anos: [],
            exibeDocumento: false,
            naoEncontrato: false,
            headerContratos: [
                {
                    text: 'Contrato',
                    align: 'start',
                    sortable: false,
                    value: 'nr_contrato',
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Data',
                    value: 'dt_credito',
                    sortable: false,
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Prazo',
                    value: 'nr_prazo',
                    sortable: false,
                    width: 40,
                    class: 'title white--text'
                },
                {
                    text: 'Parcela',
                    value: 'vl_pmt',
                    sortable: false,
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: 'Saldo',
                    value: 'saldo',
                    sortable: false,
                    width: 100,
                    class: 'title white--text'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'end',
                    sortable: false,
                    width: 100,
                    class: 'title white--text'
                },
            ],
         }
      },

      created: function() {

        let params = {
            api: this.$API,
            descricao: this.titulo
        }

        this.$store.dispatch('logatendimento', params);

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.inicia();

        
      },
            
 
      methods: {



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        

        //######################################
        //# Busca os contratos do participante
        //###################################### 

        inicia(){

          this.loading = true;

          this.$API.get( '/api/geral/agora')
          .then(response => {

            var agora = response.data;
            var ano = parseInt(agora.substr(0,4));

            ano = ano -1;

            this.anos = [];
            for(var i=0; i < 5; i++){
                
                this.anos.push(ano);
                ano = ano -1;
            }


            this.loading = false;             

          })
          .catch(error => {
              this.loading = false;
          });

        },

        
        
        
        
        
        informe(){

          if( this.$refs.form.validate() ){

            this.loading = true;


            var params = {
                ano: this.ano
            }

            this.$API.post( '/api/emprestimo/pdfinforme', params)
            .then(response => {

              if(response.data.codigo =='ok'){
                this.fileURL = response.data.arquivo;
                this.exibeDocumento = true;
              }else{
                this.mensagem = response.data.mensagem;
                this.naoEncontrato = true;
              }
              
              this.loading = false;             

            })
            .catch(error => {
                this.loading = false;
            });

          }

          
          

        },



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>