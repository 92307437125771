<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo}}</v-toolbar-title>
              </v-toolbar>


              <v-card-text style="height:95%">
              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            

              <v-row v-if="!loading && (naoEncontrado || !TemPlano)">
                <v-col cols="12" >
                  
                    <v-alert class="text-h5" text type="info" color="blue darken-4" >{{mensagem}}</v-alert>
                  
                </v-col>
              </v-row> 




              <v-row v-if="!loading && exibeDocumento" class="fill-height mt-n5" >
                <v-col cols="12" md="12" >
                    <v-card-text style="height:98%">     
                      <iframe :src="'data:Application/pdf;base64,'+ fileURL" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>
                    </v-card-text>
                </v-col>
                
              </v-row> 
              

              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo: "Extrato BPA",
            digitar_valor: false,
            form_valido: true,
            loading: true,
            loading2: true,
            loading_fator: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            fileURL: null,
            ano_mes_ini: null,
            ano_mes_fim: null,
            anos_meses: [],
            exibeDocumento: false,
            naoEncontrado: false,
            TemPlano: false,
            
         }
      },

      created: function() {

        let params = {
            api: this.$API,
            descricao: this.titulo
        }

        this.$store.dispatch('logatendimento', params); 

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        

        this.inicia();

        
      },
            
 
      methods: {



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        

        //######################################
        //# Busca o extrato do participante
        //###################################### 

        inicia(){

          this.loading = true;
          this.naoEncontrado = false;
          this.TemPlano = false;

          let len = this.$store.state.planos_usuario.length;
          for(let i = 0; i<len; i++){
              if(this.$store.state.planos_usuario[i].id_plano == 1){
                this.TemPlano = true;
              }
          }

          if(this.TemPlano){

            var params = {
                cpf: this.$store.state.pessoa_fisica.cpf,
            }

            this.$API.post( '/api/extratobpa/pdfextrato', params)
            .then(response => {

              console.log(response.data);

              if(response.data.codigo =='ok'){
                this.fileURL = response.data.arquivo;
                this.exibeDocumento = true;
              }else{
                this.mensagem = response.data.mensagem;
                this.naoEncontrado = true;
              }

              this.loading = false;             

            })
            .catch(error => {
                this.loading = false;
            });

          }else{
            this.mensagem = "O Extrato BPA está disponível somente para os participantes do PSI"
            this.loading = false;
          }


          

        },

        



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>