<template>
  <v-app id="inspire">
    <v-main>
        <router-view v-if="usuario.logado"></router-view>
    </v-main>
    <Sidebar />    
  </v-app>
</template>




<script>
import Sidebar from './components/Sidebar.vue'

export default {
  name: 'App',
  components: {
    Sidebar, 
  },
  
  data: function(){
    return {
      usuario: {
        logado: true
      },
      drawer: null,
    }
  },

  created: function(){

  },  

}
</script>









