var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":"4000","color":_vm.alerta_color},model:{value:(_vm.exibe_alerta),callback:function ($$v) {_vm.exibe_alerta=$$v},expression:"exibe_alerta"}},[_vm._v(" "+_vm._s(_vm.alerta)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"rounded-0",attrs:{"flat":""}},[_c('v-img',{attrs:{"src":"img/saco_dinheiro.png"}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","tile":""}},[_c('v-app-bar-nav-icon',{staticClass:"d-lg-none ml-n6",on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('switchSidebar');}}}),_c('v-toolbar-title',{staticClass:"text-h5 ml-n5"},[_vm._v(_vm._s(_vm.titulo_pagina))])],1),(_vm.loading)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)])],1):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_vm._v("Carregando")])])],1):_vm._e(),(!_vm.loading && !_vm.exibeDocumento)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headerContratos,"items":_vm.contratos,"no-data-text":"Não existem contratos.","items-per-page":-1,"loading":_vm.loading.anexo,"loading-text":"Carregando contratos...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dt_credito",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.dt_credito).toLocaleString("pt-BR",{dateStyle: "short"})))])]}},{key:"item.vl_pmt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.vl_pmt)))])]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.saldo)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.ic_controle_judicial == 'N')?_c('v-btn',{staticClass:"no-uppercase",attrs:{"text":""},on:{"click":function($event){return _vm.exibecontrato(item.id_contrato_emp,item.nr_contrato)}}},[_vm._v("Exibir parcelas atrasadas")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1):_vm._e(),(!_vm.loading && _vm.exibeDocumento)?_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary","rounded":"","dark":""},on:{"click":function($event){_vm.exibeDocumento=false;_vm.fileURL=null;}}},[_vm._v("Voltar")])],1)],1)],1):_vm._e(),(!_vm.loading && _vm.exibeDocumento)?_c('v-row',{staticClass:"fill-height mt-n5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',{staticStyle:{"height":"98%"}},[_c('iframe',{staticStyle:{"border":"0","top":"0px","left":"0px","bottom":"0px","right":"0px","width":"100%","height":"100%"},attrs:{"src":'data:Application/pdf;base64,'+ _vm.fileURL,"frameborder":"0","allowfullscreen":""}})])],1)],1):_vm._e(),_c('v-overlay',{attrs:{"absolute":"","value":_vm.dialog}}),_c('v-snackbar',{attrs:{"multi-line":"","centered":"","color":_vm.cor_dialogo,"timeout":"-1","max-width":"400","min-height":"140","vertical":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [(!_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnOk(_vm.titulo)}}},'v-btn',attrs,false),[_vm._v(" Ok ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnSim(_vm.titulo)}}},'v-btn',attrs,false),[_vm._v(" Sim ")]):_vm._e(),(_vm.sim_nao)?_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){return _vm.BtnNao(_vm.titulo)}}},'v-btn',attrs,false),[_vm._v(" Não ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "+_vm._s(_vm.mensagem)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }