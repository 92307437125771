<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>

              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 

              <v-row v-if="!loading && !pode_simular">
                <v-col cols="12" >
                  <v-card-text>
                    <v-alert class="text-h5" text type="info" color="blue darken-4" >{{mensagem}}</v-alert>
                  </v-card-text>
                </v-col>
              </v-row> 
            
              <!-- 
              ####################################################
              # Painel de SIMULAÇÃO
              ####################################################
              -->
        
              <v-row v-if="!loading">
                <v-col cols="12" md="7" >
                  <v-card-text>     

                    <v-row v-if="planos.length > 1">
                      <v-col cols="12" md="5">
                        <div class="text-h5 font-weight-light">Plano/Situação</div>
                      </v-col>
                      <v-col cols="12" md="7">
                          <v-select
                            v-model="plano"
                            outlined
                            dense
                            :items="planos"
                            return-object
                            :rules="Rules.Required"
                            @change="iniciar"
                            no-data-text="Carregando..."
                          >
                          <template slot="selection" slot-scope="data">
                            {{ data.item.sigla_plano }} - {{ data.item.data_inicio_plano.substr(8,2)+'/'+data.item.data_inicio_plano.substr(5,2)+'/'+data.item.data_inicio_plano.substr(0,4) }} - {{ data.item.situacao }} 
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ data.item.sigla_plano }} - {{ data.item.data_inicio_plano.substr(8,2)+'/'+data.item.data_inicio_plano.substr(5,2)+'/'+data.item.data_inicio_plano.substr(0,4) }} - {{ data.item.situacao }}
                          </template>
                          </v-select>
                        
                      </v-col>
                    </v-row> 

                    <v-row v-if="!loading && pode_simular">
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerSaldos"
                          :items="saldos"
                          class="table"
                          no-data-text="Não há nenhum saldo"
                          :items-per-page="-1"
                          hide-default-footer
                          >

                          <template v-slot:item.valor="{ item }">
                            <span v-if="item.tipo != ''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.valor) }}</span>
                          </template>

                        </v-data-table>
                      </v-col>
                    </v-row> 

                    <v-row v-if="!loading && pode_simular && mostra_dois_irrf">
                      <v-col cols="12">
                        <p class="text-h5 mb-n4"  >Opção pelo IR progressivo</p>
                      </v-col>
                    </v-row>   

                    <v-row v-if="!loading && pode_simular && (mostra_dois_irrf || (!mostra_dois_irrf && plano.tipo_tributacao =='P'))">
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerDescontos"
                          :items="descontos_progressivo"
                          class="table"
                          no-data-text="Não há nenhum desconto"
                          :items-per-page="-1"
                          hide-default-footer
                          >

                          <template v-slot:item.valor="{ item }">
                            <span v-if="item.tipo != ''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.valor) }}</span>
                          </template>

                        </v-data-table>
                      </v-col>
                    </v-row>    

                    <v-row v-if="!loading && !exibe_mensagem && pode_simular && (mostra_dois_irrf || (!mostra_dois_irrf && plano.tipo_tributacao =='P'))">
                      <v-col cols="12">
                        <div class="text-h5 text-right">Resgate Líquido: <b class="text-h5 text-no-wrap"> {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(resgate_liquido_progressivo)}} </b></div>
                      </v-col>
                    </v-row> 

                    <v-row v-if="!loading && pode_simular && mostra_dois_irrf">
                      <v-col cols="12">
                        <p class="text-h5 mb-n4"  >Opção pelo IR regressivo</p>
                      </v-col>
                    </v-row>

                    <v-row v-if="!loading && pode_simular && (mostra_dois_irrf || (!mostra_dois_irrf && plano.tipo_tributacao =='R'))">
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerDescontos"
                          :items="descontos_regressivo"
                          class="table"
                          no-data-text="Não há nenhum desconto"
                          :items-per-page="-1"
                          hide-default-footer
                          >

                          <template v-slot:item.valor="{ item }">
                            <span v-if="item.tipo != ''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.valor) }}</span>
                          </template>

                        </v-data-table>
                      </v-col>
                    </v-row> 

                    <v-row v-if="!loading && !exibe_mensagem && pode_simular && (mostra_dois_irrf || (!mostra_dois_irrf && plano.tipo_tributacao =='R'))">
                      <v-col cols="12">
                        <div class="text-h5 text-right">Resgate Líquido: <b class="text-h5 text-no-wrap"> {{Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(resgate_liquido_regressivo)}} </b></div>
                      </v-col>
                    </v-row> 


                  </v-card-text>
                </v-col> 






                
                
                
                <!-- 
                ####################################################
                # Painel de AVISOS
                ####################################################
                -->
                
                <v-col cols="12" md="5" v-if="!exibe_mensagem && pode_simular">
                  <v-card 
                    class="mt-4 mr-4 cardTransparente"
                    outlined
                  >
                    <v-card-text>     

                      <v-row>
                        <v-col cols="12" md="12">

                        <p class="text-body-1 text-justify">(*) Crédito tributário para fins exclusivo de abatimento da base de cálculo do imposto de renda. Não compõe o saldo de contas.</p>
                        <p class="text-body-1 text-justify">Cálculo efetuado sem considerar dependentes para Imposto de Renda</p>
                        <p class="text-body-1 text-justify">Saldo Calculado com base na cota de {{ valor_cota }} de {{ new Date(data_cota).toLocaleString("pt-BR",{dateStyle: "short"}) }}</p>
                        <p class="text-body-1 "><b>Participantes do PSI</b></p>
                        <p class="text-body-1 text-justify">Utilizada a Tabela Progressiva nas alíquotas correspondentes ao valor do resgate.</p>
                        <p class="text-body-1 "><b>Participantes do PSII</b></p>
                        <p class="text-body-1 text-justify"><b>Tabela progressiva</b> - Alíquota de 15% a título de antecipação do imposto de renda devido. Cabendo à VSa. efetuar a Declaração de Ajuste Anual para pessoas físicas (Lei 11.053/2004).</p>
                        <p class="text-body-1 text-justify"><b>Tabela Regressiva</b> - Percentual em razão do tempo de contribuição para o plano: {{aliquota_irrf_regressivo}}% (PEPS)</p>
                        <p class="text-body-1 text-justify"><b>Opção pelo regime tributário:</b> {{$store.state.planos_usuario[this.$store.state.index_plano_selecionado].tipo_tributacao =="P" ? "Progressiva (*)" : "Regressiva (*)"}} </p>
                        <p class="text-body-1 text-justify">(*) Se não tiver realizado a opção pelo regime de tributação ou tenha realizado a opção antes de 11/01/2024, caso tenha interesse, no momento da solicitação do resgate a opção poderá ser confirmada ou alterada.</p>
                        
                        </v-col>
                        
                      </v-row> 
                      
                    </v-card-text>
                  </v-card>
                </v-col>
                
              </v-row>   
            


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: "Consulta Resgate de Saldo",
            form_valido: true,
            loading: true,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            exibe_mensagem: false,
            saldos: [],
            descontos_progressivo:[],
            descontos_regressivo:[],
            resgate_liquido_progressivo: 0,
            resgate_liquido_regressivo: 0,
            valor_cota: 0,
            data_cota: null,
            pode_simular: false,
            plano: null,
            planos: [],
            mostra_dois_irrf: true,
            aliquota_irrf_regressivo: 0,
            headerSaldos: [
                {
                    text: 'Saldos',
                    align: 'start',
                    sortable: false,
                    value: 'descricao',
                    width: 80,
                    class: 'title white--text'
                },
                {
                    text: 'Valor',
                    value: 'valor',
                    align: 'end',
                    sortable: false,
                    width: 20,
                    class: 'title white--text'
                },
            ],
            headerDescontos: [
                {
                    text: 'Descontos',
                    align: 'start',
                    sortable: false,
                    value: 'descricao',
                    class: 'title white--text'
                },
                {
                    text: 'Valor',
                    value: 'valor',
                    align: 'end',
                    sortable: false,
                    class: 'title white--text'
                },
            ],
         }
      },




      created: function() {

        let params = {
          api: this.$API,
          descricao: this.titulo_pagina
        }

        

        this.$store.dispatch('logatendimento', params); 

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.pode_simular = false;
        this.loading = true;

        let len = this.$store.state.planos_usuario.length;

        var categorias =  [1,3,4,9];
        var situacoes =  [1,2,3,6,9,10,12,17,18];
        this.planos = this.$store.state.planos_usuario.filter(plano_usuario => (plano_usuario.ja_resgatou == false) && (plano_usuario.portabilidade_saida == false) && categorias.indexOf(plano_usuario.id_categoria) != -1 && situacoes.indexOf(plano_usuario.id_situacao) != -1 );

        if(this.planos.length > 1){
          this.planos.sort((a, b) => (a.id_plano > b.id_plano) ? 1 : -1);
          this.plano = this.planos[0];
          this.pode_simular = true;
          this.iniciar();

        }else if(this.planos.length == 1){
          this.plano = this.planos[0];
          this.pode_simular = true;
          this.iniciar();
        }else{
          this.plano = null;
          this.pode_simular = false;
          this.mensagem = "Simulador de resgates só está disponível para participantes ativos ou desligados que ainda não resgataram ou ainda não realizaram portabilidade de saída.";
          this.exibe_mensagem = true;
          
        }
        

        this.loading = false;
        
      },
           
 
      methods: {


        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  



        //######################################
        //# Inicia o simulador
        //###################################### 

        iniciar(){

          //var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

        

          if(this.plano != null){

            this.pode_simular = true;

            var params = {
              id_pessoa_participante: this.plano.id_pessoa,
              id_plano_participante: this.plano.id_plano,
              dt_ini_plano: this.plano.data_inicio_plano,
              tipo_tributacao: this.plano.tipo_tributacao
            }
              
              this.loading = true;
              this.$API.post( '/api/resgate/consultar', params)
              .then(response => { 

                  this.saldos = response.data.saldos;
                  this.descontos_progressivo = response.data.descontos_progressivo;
                  this.descontos_regressivo = response.data.descontos_regressivo;
                  this.resgate_liquido_progressivo = response.data.resgate_liquido_progressivo;        
                  this.resgate_liquido_regressivo = response.data.resgate_liquido_regressivo;          
                  this.valor_cota = response.data.valor_cota; 
                  this.data_cota = response.data.data_cota;  
                  this.aliquota_irrf_regressivo = response.data.aliquota_irrf_regressivo;  

                  this.loading = false; 
                  
                  
              })
              .catch(error => {
                  this.TrataErros(error);
              }); 
          }else{
            this.pode_simular = false;
            this.mensagem = "O Simulador de resgates não está disponível para voçê";
            this.loading = false;
          }

        },





        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>