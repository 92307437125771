<template>
  <v-container fluid>
      <v-form ref="form" v-model="form_valido" lazy-validation >  
        <v-row v-if="participante.cpf != null">
          <v-col>
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-if="!somente_leitura" rounded class="mr-3 " color="primary"   @click="submitForm(false)"> <v-icon>mdi-content-save</v-icon> Salvar </v-btn>
              </v-toolbar>     
          </v-col>

        </v-row>

        <v-row v-if="participante.cpf == null && !dialog " >
          <v-col>
            <div class="text-center" >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>  
        <v-row v-if="participante.cpf == null && !dialog" >
          <v-col>
            <div class="text-center" >Carregando</div>
          </v-col>
        </v-row> 


        <v-row v-if="participante.cpf != null  ">
          <v-col>
            <v-card color="#ECEFF1" flat>
              <v-toolbar color="primary" flat dense dark>
                    <v-toolbar-title>
                      <v-row align="center">
                          <v-col class="shrink">Dados pessoais</v-col>
                      </v-row>
                    </v-toolbar-title>
              </v-toolbar>
                  <v-card-text >
                  <div>
                    <v-row class="mb-n5">
                      <v-col cols="12" md="2">
                        <v-text-field  dense v-model="participante.cpf" label="* CPF"  :rules="Rules.Required"  readonly></v-text-field>
                      </v-col>  
                      <v-col cols="12" md="7"> 
                        <v-text-field dense v-model="participante.nome" label="* Nome"  :rules="[...Rules.Required, ...maxLength(60)]" readonly></v-text-field>
                      </v-col>  
                      <v-col cols="12" md="3">
                        <v-text-field  dense :value="participante.data_nascimento.substr(8,2)+'/'+participante.data_nascimento.substr(5,2)+'/'+participante.data_nascimento.substr(0,4)" label="* Data de nascimento"  :rules="[...Rules.Required]" readonly></v-text-field>
                      </v-col> 
                    </v-row>  
                    <v-row class="mb-n5">
                      <v-col cols="12" md="2"> 
                        <v-select
                          label="Sexo"
                          v-model="participante.sexo"
                          :items="sexo"
                          item-text="descricao"
                          item-value="id"
                          readonly
                          dense
                        > 
                        </v-select>
                      </v-col> 
                      <v-col cols="12" md="2"> 
                        <v-text-field  dense v-model="plano1" label="Plano" v-if="plano1" readonly ></v-text-field>
                      </v-col> 
                      <v-col cols="12" md="2">
                        <v-text-field  dense v-model="plano2" label="Plano" v-if="plano2" readonly ></v-text-field>
                      </v-col> 
                      <v-col cols="12" md="2">
                        <v-text-field  dense v-model="plano3" label="Plano" v-if="plano3" readonly ></v-text-field>
                      </v-col> 
                      <v-col cols="12" md="2">
                        <v-text-field v-if="ultima_atualizacao !=''" dense  :value="ultima_atualizacao.substr(8,2)+'/'+ultima_atualizacao.substr(5,2)+'/'+ultima_atualizacao.substr(0,4)" label="Última Atualização" readonly></v-text-field>
                      </v-col> 
                      <v-col cols="12" md="2">
                        <v-text-field dense  v-model="protocolo" label="Nro. Protocolo" readonly></v-text-field>
                      </v-col> 
                    </v-row>
                  </div>  
                  </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="participante.cpf != null">
          <v-col>
            
              <v-card color="#ECEFF1" flat>
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Informações Cadastrais</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text>
                    <div>
                      <v-row class="mb-n5">
                        <v-col cols="12" md="2">
                          <v-select
                            label="* Nacionalidade"
                            v-model="participante.id_nacionalidade"
                            :items="nacionalidade"
                            item-text="descricao"
                            item-value="id"
                            dense
                            :rules="Rules.Required"
                            :readonly="somente_leitura" 
                          > 
                          </v-select>
                        </v-col>  
                        <v-col cols="12" md="2"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.naturalidade" label="* Naturalidade"  :rules="[...Rules.Required, ...maxLength(30)]"></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <v-select
                            label="* Estado civil"
                            v-model="participante.id_estado_civil"
                            :items="estado_civil"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="Rules.Required"
                          > 
                          </v-select>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <v-select
                            label="Escolaridade"
                            v-model="participante.id_escolaridade"
                            :items="escolaridade"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                          > 
                          </v-select>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <v-select
                            label="* Politicamente Exposto"
                            v-model="participante.pep"
                            :items="sim_nao"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="Rules.Required"
                          > 
                          </v-select>
                        </v-col> 
                        <v-col cols="12" md="2"> 
                          <v-select
                            label="* Aposentado INSS"
                            v-model="participante.aposentado_inss"
                            :items="sim_nao"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="[...Rules.Required, ...aposInss(participante.aposentado_inss, participante.sexo)]"
                          > 
                          </v-select>
                        </v-col> 
                      </v-row>  

                      <v-row class="mb-n5">
                        <v-col cols="12" md="2">
                          <v-select
                            label="* Resp. FATCA"
                            v-model="participante.responsavel_fatca"
                            :items="sim_nao"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="Rules.Required"
                          > 
                          </v-select>
                        </v-col> 
                        <v-col cols="12" md="5">
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.pai" label="Nome do pai" :rules="maxLength(60)" ></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="5"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.mae" label="* Nome da mãe"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                        </v-col> 
                      </v-row>
                    </div>  
                    </v-card-text>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="participante.cpf != null">
          <v-col>
            
              <v-card color="#ECEFF1" flat>
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Informações de Endereço e Contatos</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                    <v-card-text>
                    <div>
                      <v-row class="mb-n5">
                        <v-col cols="12" md="3">
                          <v-select
                            label="* País"
                            v-model="participante.id_pais"

                            :items="paises"
                            item-text="nome"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="Rules.Required"
                            @change="VerificaPais"
                          > 
                          </v-select>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <v-text-field 
                             :readonly="somente_leitura" 
                             :disabled = "participante.id_pais != 31"
                             dense 
                             v-model="participante.cep" 
                             label="* CEP"  
                             :rules="[...maxLength(8), ...cep(participante.cep)]"  
                             @change="consultaCEP(participante.cep, participante)"></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="5"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.cidade" label="* Cidade"  :rules="[...Rules.Required, ...maxLength(30)]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-select
                            :disabled = "participante.id_pais != 31"
                            label="* Estado"
                            v-model="participante.estado"
                            :items="estados"
                            dense
                            :rules="[...Estado(participante.cep,participante.estado)]"
                            :readonly="somente_leitura" 
                          > 
                          </v-select>
                        </v-col>  
                          



                      </v-row>  

                      <v-row class="mb-n5">

                        <v-col cols="12" md="3">
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.bairro" label="* Bairro"  :rules="[...Rules.Required, ...maxLength(20)]"></v-text-field>
                        </v-col>  
                        
                        <v-col cols="12" md="4"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.endereco" label="* Endereço"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="2"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.numero" label="* Número"  :rules="[...Rules.Required, ...maxLength(10)]"></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="3"> 
                          <v-text-field :readonly="somente_leitura"  dense v-model="participante.complemento" label="Complemento" :rules="maxLength(40)" ></v-text-field>
                        </v-col> 
 
                      </v-row>

                      <v-row class="mb-n5">
                        <v-col cols="12" md="3">
                          <v-text-field :readonly="somente_leitura"  autocomplete="none" dense v-model="participante.email_pessoal" label="* Email Pessoal"  :rules="[...Rules.Required, ...maxLength(60), ...email(participante.email_pessoal)]"></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="3">
                          <v-text-field :readonly="somente_leitura"  autocomplete="none" dense v-model="participante.email_extra" label="Email Pessoal Extra" :rules="[...maxLength(60), ...email(participante.email_extra)]"></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="participante.celular" label="* Celular"  :rules="[...Rules.Required, ...maxLength(15), ...telefone(participante.celular)]"></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="participante.telefone" label="Telefone Alternativo" :rules="[...maxLength(15)]" ></v-text-field>
                        </v-col> 

                      </v-row>

                    </div>  
                    </v-card-text>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="participante.cpf != null && aposentado_pensionista == null"> 
          <v-col>
              <v-card color="#ECEFF1" flat>
                <v-toolbar color="primary" flat dense dark>
                  <v-toolbar-title>
                    <v-row align="center">
                        <v-col class="shrink">Informações das Empresas</v-col>
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-actions class="mb-n5">
                  <v-spacer></v-spacer>
                  <v-btn v-if="!somente_leitura" rounded color="#B0BEC5" @click="AddEmpresa"> <v-icon>mdi-plus-circle-outline</v-icon> Adicionar </v-btn>
                </v-card-actions> 
                <v-card-text>
                  <div>
                    <v-container
                      v-for="empresa in empresas"
                      :key="empresa.id"
                    >
                      <v-row class="mb-n5">
                        <v-col cols="12" md="4">
                          <v-text-field :readonly="somente_leitura"  dense v-model="empresa.nome_empresa" label="* Nome da Empresa"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="2"> 
                          <v-select
                            label="* Categorias"
                            v-model="empresa.id_tipo_tempo_servico"
                            :items="tipoTempoServico"
                            item-text="descricao"
                            item-value="id"
                            :readonly="somente_leitura" 
                            dense
                            :rules="Rules.Required"
                          > 
                          </v-select>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <v-text-field :readonly="somente_leitura"  type="date" dense v-model="empresa.data_admissao" label="* Data de admissão"  :rules="[...Rules.Required, ...DataMaiorQAtual(empresa.data_admissao)]" @change="IdadeAdmissao(empresa.data_admissao)" ></v-text-field>
                        </v-col> 
                        <v-col cols="12" md="2">
                          <v-text-field :readonly="somente_leitura"  type="date" dense v-model="empresa.data_demissao" label="* Data de demissão"  :rules="DataMenor(empresa.data_admissao, empresa.data_demissao)" ></v-text-field>
                        </v-col>  
                        <v-col cols="12" md="2">
                          <v-btn v-if="!somente_leitura" rounded color="#B0BEC5" @click="DelEmpresa(empresa.id)" > <v-icon>mdi-minus-circle-outline</v-icon> Excluir </v-btn>
                        </v-col> 
                      </v-row>
                    </v-container>
                  </div>  
                </v-card-text>
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="participante.cpf != null && this.participante_planos.length != 0">
          <v-col>
              <v-card color="#ECEFF1" flat>
                <v-toolbar color="primary" flat dense dark>
                      <v-toolbar-title>
                        <v-row align="center">
                            <v-col class="shrink">Informações dos Beneficiários e Designados</v-col>
                        </v-row>
                      </v-toolbar-title>
                </v-toolbar>
                <v-card-text >
                    <v-container
                      v-for="dependente in dependentes"
                      :key="dependente.id_dependente"
                      fluid
                    >
                      <v-card color="#ECEFF1">
                        <v-card-text>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="4">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.nome" label="* Nome"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.cpf" label="* CPF"  :rules="[...Rules.Required, ...maxLength(11), ...cpf(dependente.pessoa_fisica.cpf)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-select
                                label="* Parentesco"
                                v-model="dependente.id_parentesco"
                                :items="parentesco"
                                item-text="descricao"
                                item-value="id"
                                dense
                                :rules="[...Rules.Required]"
                                @input="Bigamia()" 
                                :readonly="somente_leitura" 
                              > 
                              </v-select>
                            </v-col>
                            <v-col cols="12" md="2">
        
                              <v-text-field :readonly="somente_leitura"  type="date" dense v-model="dependente.pessoa_fisica.data_nascimento" label="* Data de nascimento"  :rules="[...Rules.Required]" @change="IdadeParente(dependente.id_parentesco, participante.data_nascimento, dependente.pessoa_fisica.data_nascimento);" ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-select
                                label="* Inválido"
                                v-model="dependente.invalido"
                                :items="sim_nao"
                                item-text="descricao"
                                item-value="id"
                                :readonly="somente_leitura" 
                                dense
                                :rules="Rules.Required"
                              > 
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="2">
                              <v-select
                                label="* Sexo"
                                v-model="dependente.pessoa_fisica.sexo"
                                :items="sexo"
                                item-text="descricao"
                                item-value="id"
                                dense
                                :rules="[...Rules.Required]"
                                :readonly="somente_leitura" 
                              > 
                              </v-select>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-select
                                label="Escolaridade"
                                v-model="dependente.pessoa_fisica.id_escolaridade"
                                :items="escolaridade"
                                item-text="descricao"
                                item-value="id"
                                :readonly="somente_leitura" 
                                dense
                              > 
                              </v-select>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field  
                                 dense 
                                 v-model="dependente.pessoa_fisica.cep" 
                                 label="CEP" 
                                 :rules="[...maxLength(8)]"
                                 @change="consultaCEP(dependente.pessoa_fisica.cep, dependente.pessoa_fisica)"
                                 :readonly="somente_leitura" 
                              ></v-text-field>
                                                              
                            </v-col>
                            <v-col cols="12" md="1">
                              <v-select
                                label="Estado"
                                v-model="dependente.pessoa_fisica.estado"
                                :items="estados"
                                dense
                                :readonly="somente_leitura" 
                              > 
                              </v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.cidade" label="Cidade" :rules="[...maxLength(30)]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.bairro" label="Bairro" :rules="[...maxLength(20)]"></v-text-field>
                            </v-col>

                          </v-row>
                          <v-row class="mb-n5">
                            <v-col cols="12" md="6">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.endereco" label="Endereço" :rules="[...maxLength(60)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.numero" label="Número" :rules="[...maxLength(10)]"></v-text-field>
                            </v-col> 
                            <v-col cols="12" md="4">
                              <v-text-field :readonly="somente_leitura"  dense v-model="dependente.pessoa_fisica.complemento" label="Complemento" :rules="[...maxLength(40)]"></v-text-field>
                            </v-col>

                            
                          </v-row>
                          <v-row class="mb-n5">

                            <v-col cols="12" md="4">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.pessoa_fisica.email_pessoal" label="* Email Pessoal"  :rules="[...Rules.Required, ...maxLength(60)]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.pessoa_fisica.email_extra" label="Email Pessoal Extra" :rules="[...maxLength(60)]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.pessoa_fisica.celular" label="* Celular"  :rules="[...Rules.Required, ...maxLength(15)]"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field :readonly="somente_leitura"  dense autocomplete="none" v-model="dependente.pessoa_fisica.telefone_alternativo" label="Telefone Alternativo" :rules="[...maxLength(15)]"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-container
                          v-for="plano in FiltraPlanoDependente(dependente.id_dependente)"
                          :key="plano.id"
                          fluid
                        >
                          <v-card color="#CFD8DC">
                            <v-card-text>
                              <v-row class="mb-n5">
                                <v-col cols="12" md="3">
                                  <v-select
                                    label="* Plano"
                                    v-model="plano.id_plano"
                                    :items="planos"
                                    item-text="sigla"
                                    item-value="id"
                                    readonly
                                    dense
                                  > 
                                  </v-select>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-select
                                    label="* Beneficiário"
                                    v-model="plano.beneficiario"
                                    :items="sim_nao"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required, ...Finalidade(dependente.id_dependente)]"
                                    :readonly="somente_leitura" 
                                  > 
                                  </v-select>
                                </v-col> 
                                <v-col cols="12" md="2">
                                  <v-select
                                    label="* Designado"
                                    v-model="plano.designado"
                                    :items="sim_nao"
                                    item-text="descricao"
                                    item-value="id"
                                    dense
                                    :rules="[...Rules.Required, ...Finalidade(dependente.id_dependente)]"
                                    :readonly="somente_leitura" 
                                  > 
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <v-text-field :readonly="somente_leitura"  dense v-model="plano.percentual" :rules="[...Rateio(plano.id_plano, plano.percentual, plano.designado)]"  label="Perc. pecúlio"  ></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-spacer></v-spacer>
                                  <!--<v-btn rounded color="#B0BEC5"  @click=DelPlano(plano.id) > <v-icon>mdi-minus-circle-outline</v-icon></v-btn> -->
                                </v-col>
                              </v-row> 
                            </v-card-text>
                          </v-card>    
                        </v-container> 
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn v-if="!somente_leitura" rounded color="#B0BEC5" @click=DelDependente(dependente.id_dependente) > <v-icon>mdi-minus-circle-outline</v-icon> Excluir pessoa</v-btn>
                         <!-- <v-btn rounded color="#B0BEC5"  @click=AddPlano(dependente.id_dependente) > <v-icon>mdi-plus-circle-outline</v-icon> Adicionar Plano</v-btn> -->
                        </v-card-actions> 
                      </v-card>  
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!somente_leitura" class="mb-2" rounded color="#B0BEC5" @click="AddDependente" > <v-icon>mdi-plus-circle-outline</v-icon> Adicionar Beneficiário ou Designado </v-btn>
                </v-card-actions> 
              </v-card>
          </v-col>
        </v-row>

        <v-row v-if="participante.cpf != null && !somente_leitura ">
          <v-col>
              <v-toolbar flat dense tile >

                <v-spacer></v-spacer>
                <v-btn rounded class="mr-3 " color="primary"   @click="submitForm(false)"> <v-icon>mdi-content-save</v-icon> Salvar </v-btn>
              </v-toolbar>     
          </v-col>
        </v-row>

        <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
          <v-progress-circular indeterminate size="64" ></v-progress-circular>
        </v-overlay>

        <v-overlay
          absolute
          :value="dialog"
        >
        </v-overlay>

        <v-snackbar
          v-model="dialog"
          multi-line
          centered
          :color="cor_dialogo"
          timeout="-1"
          max-width="400"
          min-height="140"
          vertical
        >
          

          <template v-slot:default>
            <div v-html="mensagem"> </div>
          </template>

          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnOk(titulo)"
              v-if="!simnao"
            >
              Ok
            </v-btn>
            <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnSim(titulo)"
              v-if="simnao"
            >
              Sim
            </v-btn>
                    <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="BtnNao(titulo)"
              v-if="simnao"
            >
              Não
            </v-btn>
          </template>
        </v-snackbar>

      </v-form>  
  </v-container>
</template>

<script>
   export default {

      data: function(){
         return {
            titulo_pagina: "Cadastro",
            value: "12.34",        // 12.34 or "12.34" or "" or null
            label: "Percent",
            focus: false,
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            multiLine: true,
            overlay:false,
            absolute: false,
            form_valido:true,
            loading: false,
            mensagem: '',
            menu_aniv: false,
            dialog: false,
            simnao: false,
            cor_dialogo: '#B00020',
            titulo: null,
            idade_titular: null,
            confirmou_idade: true,
            protocolo: '',
            ultima_atualizacao:'',
            participante:{
              id: null,
              cpf: null,
              nome: null,
              data_nascimento: "",
              sexo: null,
              id_nacionalidade: null,
              naturalidade: null,
              id_estado_civil: null,
              id_escolaridade: null,
              pep: null,
              aposentado_inss: null,
              responsavel_fatca: null,
              pai:null,
              mae: null, 
              estado: null,
              uf_viacep: null,
              cep: '',
              celular: null,
              cidade: null,
              telefone: null,
              bairro: null,
              email_pessoal: null,
              email_extra: null,
              endereco: null,
              numero: null,
              complemento: null,
              id_pais: null
            },
            participante_planos: [],
            empresas: [],
            dependentes: [],
            dependentes_planos:[],
            planos:[],
            planos_aux:[],
            nacionalidade:[],
            estado_civil:[],
            parentesco:[],
            paises:[],
            plano1:null,
            plano2:null,
            plano3:null,
            escolaridade:[],
            tipoTempoServico:[],
            sim_nao:[
              {id:"S", descricao: "SIM"},
              {id:"N", descricao: "NÃO"},
            ],
            estados:["", "AC", "AL",	"AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"],
            sexo:[
              {id:"M", descricao:"MASCULINO"},
              {id:"F", descricao:"FEMININO"},
            ],
            teste: 0,
            aposentado_pensionista: null,
            somente_leitura: true,
            uf_viacep:'',
            mensagem_erro_cep: '',
         }
      },
      created: function() {
        
        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params); 

        this.recuperaTabelas();
        this.recuperaPessoa();

        
      },
      
      methods: {

        //#####################
        //Regras de validação do formulário
        //####################
        Rateio: function(id_plano, numero, designado) {
          let numero_aux = numero.replace(",",".");
          if(!isNaN(numero_aux)){
            let perc_aux = 0;
            let soma = 0;
            
            for (let x = 0; x < this.dependentes_planos.length; x++) {
              if(this.dependentes_planos[x].id_plano == id_plano ){
                if(this.dependentes_planos[x].percentual.indexOf(",") >= 0){
                  perc_aux = this.dependentes_planos[x].percentual.replace(",",".");
                }else{
                  perc_aux = this.dependentes_planos[x].percentual;
                }
                soma = soma + parseFloat(perc_aux);
              }
            } 

            let aprovado = false;
            if(soma ==0){
               return  true ;
            }

            if(soma ==100){
              if(!isNaN(numero_aux)){
                if(designado == 'S' && parseFloat(numero_aux) == 0){
                  return  false || 'Percentual deve ser maior que zero'  //false mostra a mensagem
                }
                if(designado == 'N' && parseFloat(numero_aux) != 0){
                  return  false || 'Percentual deve ser zero'  //false mostra a mensagem
                }

              }
              return  true ;

            }else{
              return  false || 'Somatório deve ser 100%'  //false mostra a mensagem
            }

            
          }else{
            return false || 'Somente números'
          }  
        },


        Finalidade: function(id_dependente) {

          let aprovado = true;
          let mensagem = '';

          let planos = this.FiltraPlanoDependente(id_dependente);
          let qtd = planos.length;

          let tem_finalidade = false;
          for( let i = 0; i<qtd; i++ ){
            if(planos[i].beneficiario == 'S' || planos[i].designado == 'S'){
              tem_finalidade = true;
            }
          }

          if(!tem_finalidade){
            aprovado = false;
            mensagem = 'Dependente deve ser beneficiário ou designado';
          }

          return  [aprovado || mensagem];
          
        },

        
        maxLength: function(max) {
          return [v => (v || '' ).length <= max || 'Máximo '+max+' caracteres']
        },


        telefone: function(telefone) {
          
          let aprovado = true;
          let mensagem = '';

          if(telefone != null){

            if(telefone.length < 10){
              aprovado = false;
              mensagem = 'Mínimo: ddd + 8 números';
            }

            if(telefone.indexOf('0000000') != -1 || 
              telefone.indexOf('1111111') != -1 || 
              telefone.indexOf('2222222') != -1 || 
              telefone.indexOf('3333333') != -1 || 
              telefone.indexOf('4444444') != -1 || 
              telefone.indexOf('5555555') != -1 || 
              telefone.indexOf('6666666') != -1 || 
              telefone.indexOf('7777777') != -1 || 
              telefone.indexOf('8888888') != -1 || 
              telefone.indexOf('9999999') != -1 || 
              telefone.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'Número inválido';
            }
          }  

          return  aprovado || mensagem
        },
        cep: function(cep) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){

            if(cep.length < 8){
              aprovado = false;
              mensagem = 'Mínimo 8 números';
            }

            if(cep.length < 1){
              aprovado = false;
              mensagem = 'Obrigatório';
            }

            if(cep.indexOf('0000000') != -1 || 
              cep.indexOf('1111111') != -1 || 
              cep.indexOf('2222222') != -1 || 
              cep.indexOf('3333333') != -1 || 
              cep.indexOf('4444444') != -1 || 
              cep.indexOf('5555555') != -1 || 
              cep.indexOf('6666666') != -1 || 
              cep.indexOf('7777777') != -1 || 
              cep.indexOf('8888888') != -1 || 
              cep.indexOf('9999999') != -1 || 
              cep.indexOf('1234567') != -1
              ){

              aprovado = false;
              mensagem = 'CEP inválido';
            }

          }
          
          return  aprovado || mensagem
        },


        email: function(email) {

          var resultado = true;
          var mensagem = '';

          if (email != null && email != '' ){
            var upper_email = email.toUpperCase();
            
            if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(upper_email))
            {
              resultado = false;
              mensagem = 'E-mail inválido';
            }



            if( upper_email.indexOf("SERPROS.COM") != -1 || upper_email.indexOf(".GOV.BR") != -1 ){
              resultado = false;
              mensagem = 'use seu e-mail particular';
            }
            
          }

          
          
          return  resultado || mensagem
        },

        aposInss: function(valor, sexo) {

          var resultado = true;
          var mensagem = '';
          var idademinima = 0;

          if (valor != null){
            if(valor == 'S'){

              if(sexo == 'F'){
                idademinima = 44;
              }else{
                idademinima = 49;
              }

              if(this.idade(this.participante.data_nascimento) < idademinima){
                resultado = false;
                mensagem = 'Idade inferior a '+idademinima+' anos';
              }
            }            
          }
          
          return  resultado || mensagem
        },
        DataMenor: function(data1,data2) {
        
          if(data2 != null && data2 != '' ){
            return [(data2 >= data1) || 'Demissão menor que Admissão']
          }else{
            return [true]
          }
          
        },
        IdadeTitular: function(data_nascimento) {
          this.menu_aniv = false;

          if(this.idade(data_nascimento) < 18){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade inferior a 18 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

          if(this.idade(data_nascimento) > 80){
            this.titulo = 'Atenção';
            this.mensagem = 'Esta data de nascimento indica uma pessoa com idade superior a 80 anos. Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;   
          }

        },
        IdadeParente: function(parentesco, data_titular, data_depend) {

          if(parentesco == 7){
            let ano_aux = parseInt(data_titular.substring(0,4))+10;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);
            if(data_depend < data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(titular) e filho(dependente) inferior a 10 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true;   
            }
          }

          if(parentesco == 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-18;
            data_titular = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);
        
            if(data_depend > data_titular){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade entre pai(dependente) e filho(titular) inferior a 18 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }
          }

          if(parentesco > 0 && parentesco < 5){
            
            let ano_aux = parseInt(data_titular.substring(0,4))-20;
            let data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend < data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }

            ano_aux = parseInt(data_titular.substring(0,4))+20;
            data_aux = ano_aux+'-'+data_titular.substring(5,7)+'-'+data_titular.substring(8,10);

            if(data_depend > data_aux){
              this.titulo = 'Atenção';
              this.mensagem = 'Diferença de idade superior a 20 anos. Verifique se esta informação está correta.';
              this.simnao = false;
              this.cor_dialogo ="#455A64";
              this.dialog = true; 
            }


          }


          
        },
        Bigamia: function(){
          let soma = 0;
          for (let x = 0; x < this.dependentes.length; x++) {
            if(this.dependentes[x].id_parentesco == 1 || this.dependentes[x].id_parentesco == 3){
              soma = soma + 1;
            }
          } 

          if(soma > 1){
            this.titulo = 'Atenção';
            this.mensagem = 'Existem multiplos cônjuges ou companheiros cadastrados. '+ 
                            'Verifique se esta informação está correta.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },
        lgbt: function(sexo, parentesco){
          if(parentesco > 0 && parentesco < 5){
            if(sexo == this.participante.sexo){
              this.titulo = 'Atenção';
              this.mensagem = 'Sexo do cônjuge ou companheiro igual ao sexo do titular. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;
            }
          }
          
        },
        IdadeAdmissao: function(data_admissao) {
     
          if(data_admissao != null){
            if(this.idadeNaAdmissao(this.participante.data_nascimento, data_admissao) < 16){
              this.titulo = 'Atenção';
              this.mensagem = 'Esta data de admissão indica que a pessoa tinha idade inferior a 16 anos na admissão. Verifique se esta informação está correta.';
              this.cor_dialogo ="#455A64";
              this.simnao = false;
              this.dialog = true;   
            }
          }
        },
        DataMaiorQAtual:  function(data1){
            let current = new Date();
            let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            return (data1 <= cDate) || 'Data superior a data atual'
        },
        cpf: function(cpf){
          let valido = this.validarCPF(cpf);
          return valido || 'CPF inválido'
        },
        VerificaPais: function(){

          if(this.participante.id_pais != 31){
             this.participante.cep = '';
             this.participante.estado = '';
          }
        },

        Estado: function(cep, uf){

          let resultado = true;
          let mensagem = '';

          if(this.participante.id_pais == 31){
             
             if(this.participante.estado == ''){
                resultado = false;
                mensagem = 'Obrigatório';
             }else{
                
                let cep_estado = '';

                if(typeof cep == 'string' ){
                  cep_estado = cep.substring(0,5);
                }

                if(cep_estado < '01000'){resultado = false;} 
                if(cep_estado >= '01000' && cep_estado <= '19999' && uf != 'SP'){resultado = false;}
                if(cep_estado >= '20000' && cep_estado <= '28999' && uf != 'RJ'){resultado = false;} 
                if(cep_estado >= '29000' && cep_estado <= '29999' && uf != 'ES'){resultado = false;}
                if(cep_estado >= '30000' && cep_estado <= '39999' && uf != 'MG'){resultado = false;}
                if(cep_estado >= '40000' && cep_estado <= '48999' && uf != 'BA'){resultado = false;}
                if(cep_estado >= '49000' && cep_estado <= '49999' && uf != 'SE'){resultado = false;}
                if(cep_estado >= '50000' && cep_estado <= '56999' && uf != 'PE'){resultado = false;}
                if(cep_estado >= '57000' && cep_estado <= '57999' && uf != 'AL'){resultado = false;}
                if(cep_estado >= '58000' && cep_estado <= '58999' && uf != 'PB'){resultado = false;}
                if(cep_estado >= '59000' && cep_estado <= '59999' && uf != 'RN'){resultado = false;}
                if(cep_estado >= '60000' && cep_estado <= '63999' && uf != 'CE'){resultado = false;}
                if(cep_estado >= '64000' && cep_estado <= '64999' && uf != 'PI'){resultado = false;}
                if(cep_estado >= '65000' && cep_estado <= '65999' && uf != 'MA'){resultado = false;}
                if(cep_estado >= '66000' && cep_estado <= '68899' && uf != 'PA'){resultado = false;}
                if(cep_estado >= '68900' && cep_estado <= '68999' && uf != 'AP'){resultado = false;}
                if(((cep_estado >= '69000' && cep_estado <= '69299') || (cep_estado >= '69400' && cep_estado <= '69899')) && uf != 'AM'){resultado = false;}
                if(cep_estado >= '69300' && cep_estado <= '69399' && uf != 'RR'){resultado = false;}
                if(cep_estado >= '69900' && cep_estado <= '69999' && uf != 'AC'){resultado = false;}
                if(((cep_estado >= '70000' && cep_estado <= '72799') || (cep_estado >= '73000' && cep_estado <= '73699')) && uf != 'DF'){resultado = false;}
                if(((cep_estado >= '72800' && cep_estado <= '72999') || (cep_estado >= '73700' && cep_estado <= '76799')) && uf != 'GO'){resultado = false;}
                if(cep_estado >= '76800' && cep_estado <= '76999' && uf != 'RO'){resultado = false;}
                if(cep_estado >= '77000' && cep_estado <= '77999' && uf != 'TO'){resultado = false;}
                if(cep_estado >= '78000' && cep_estado <= '78999' && uf != 'MT'){resultado = false;}
                if(cep_estado >= '79000' && cep_estado <= '79999' && uf != 'MS'){resultado = false;}
                if(cep_estado >= '80000' && cep_estado <= '87999' && uf != 'PR'){resultado = false;}
                if(cep_estado >= '88000' && cep_estado <= '89999' && uf != 'SC'){resultado = false;}
                if(cep_estado >= '90000' && cep_estado <= '99999' && uf != 'RS'){resultado = false;}
                if(cep_estado > '99999'){resultado = false;} 

                if(!resultado){
                    mensagem = 'UF não corresponde ao CEP';
                }
             }

             
          }
    
          return resultado || mensagem 
        },
        //#####################
        //Filtro de dependentes
        //####################
        FiltraPlanoDependente: function(id_dependente){
          return this.dependentes_planos.filter(function(plano) {
            return plano.id_dependente == id_dependente;
          })
        },
        //#####################
        //click dos botões da tela
        //####################
        AddEmpresa(){
          this.empresas.push({id: Math.round(Math.random()*-10000,0), 
                              nr_sequencial: null,
                              nome_empresa:"", 
                              ic_tipo_servico: "C",
                              id_tipo_tempo_servico:null, 
                              data_admissao:null, 
                              data_demissao:null, 
                              menu_admissao:false, 
                              menu_demissao:false});
        },



        DelEmpresa(id){
        
          var index =  this.empresas.map(x => {
            return x.id;
          }).indexOf(id); 

          this.empresas.splice(index, 1);
        },



        AddDependente(){
          let id_dependente = Math.round(Math.random()*-10000,0);
          
          if(this.dependentes == null){
            this.dependentes = [];
          }

          this.dependentes.push({ id_titular: this.participante.id,
                                  id_dependente: id_dependente,
                                  id_pessoa_dep: null,
                                  id_parentesco: "",
                                  invalido: "N",
                                  nivel_superior: "N",
                                  mensagem_erro_cep: '',
                                  pessoa_fisica:{
                                            nome: "",
                                            cpf: "",
                                            data_nascimento: "",
                                            menu_nascimento: false,
                                            invalido: "",
                                            sexo: "",
                                            id_escolaridade: "",
                                            estado: "",
                                            cidade: "",
                                            bairro: "",
                                            cep: "",
                                            uf_viacep: "",
                                            endereco: "",
                                            numero: "",
                                            complemento: "",
                                            celular: "",
                                            telefone_alternativo: "",
                                            email_pessoal: "",
                                            email_extra: ""}
          });

          for (let x = 0; x < this.participante_planos.length; x++) {   
            this.dependentes_planos.push({               
              id: Math.round(Math.random()*-10000,0),
              id_dependente: id_dependente,
              id_plano: this.participante_planos[x].id_plano,
              beneficiario: "N",
              designado: "N",
              percentual: "0",
              erro: true
            });                      
          }
        },



        DelDependente(id){
       
          for( var i = 0; i < this.dependentes_planos.length; i++){                       
              if ( this.dependentes_planos[i].id_dependente === id) { 
                this.dependentes_planos.splice(i, 1); 
                  i--; 
              }
          }

          var index =  this.dependentes.map(x => {
            return x.id_dependente;
          }).indexOf(id); 

          this.dependentes.splice(index, 1);
        },



        
        submitForm (gerarpdf) {

          this.form_valido = false;
          this.overlay = true;
          
          if(this.idade(this.participante.data_nascimento) > 17){
            this.confirmou_idade = true;
          }else{
            this.confirmou_idade = false;
          }



          if(this.$refs.form.validate()){

            this.idade_titular = this.idade(this.participante.data_nascimento);
            
            //ENVIA OS DADOS DO FORMULÁRIO PARA A API

            if(this.aposentado_pensionista == 'P'){
              this.empresas = [];
              this.dependentes = [];
              this.dependentes_planos = [];
            }

            if (this.dependentes == null){
              this.dependentes = [];
            }

            if (this.dependentes_planos == null){
              this.dependentes_planos = [];
            }


            let data = { 
              participante: this.participante,
              empresas: this.empresas,
              dependentes: this.dependentes,
              dependentes_planos: this.dependentes_planos,
              id_pessoa: this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_pessoa
            };




            this.$API.post( '/api/cadastro/atualizar', data)
            .then(response => {
              
              if(gerarpdf){
                  this.gerarpdf();
              }else{  
                //if(response.data.codigo =='ok'){
                  this.protocolo = response.data.protocolo;
                  this.ultima_atualizacao = response.data.ultima_atualizacao;
                  this.titulo = 'Sucesso';
                  this.mensagem = 'O recadastramento foi realizado com sucesso. Foi gerado o número de protocolo : '+this.protocolo;
                  this.cor_dialogo ="primary";
                  this.simnao = false;
                  this.dialog = true;
                //}
              }  
            })
            .catch( error =>{
                    //Erro ao salvar os dados da pessoa
                  this.titulo = 'Alerta';
                  this.mensagem = 'Ocorreu um problema e não foi possível concluir o recadastramento.';
                  this.cor_dialogo = '#B00020';
                  this.simnao = false;
                  this.dialog = true;
            }); 
            
          }else{
            this.form_valido = true;
            this.overlay = false;
            this.titulo = 'Atenção';
            this.mensagem = 'Existem problemas no preenchimento. Favor revisar o formulário.';
            this.cor_dialogo ="#455A64";
            this.simnao = false;
            this.dialog = true;
          }

        },


         BtnOk(){

          if(this.titulo != 'Atenção'){
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
            this.sair();
          }



            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';

          



           
        }, 

        BtnSim(){

          if(this.titulo == 'Sucesso'){
            this.dialog = false;
            this.titulo = '';
            this.mensagem = '';
            this.sair();
          }

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
          
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.overlay = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        },   

        sair(){


        /*  var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

          this.$store.commit('setusuariologado', usuario);*/
          this.$router.push('/');  


        }, 
        
        btnsair(){


          this.titulo = 'Sucesso';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo ="#455A64";
          this.simnao = true;
          this.dialog = true; 


        }, 
 
        //#####################
        //chamadas nas APIs
        //####################
        consultaCEP(cep, pessoa) {
          //busca o cep
          if(cep.length == 8){

            this.axios({
              method: 'get',
              url: 'https://viacep.com.br/ws/'+cep+'/json/',
            })
            .then(response => {
                        if("cep" in response.data){
                            pessoa.endereco = response.data.logradouro.toUpperCase();
                            pessoa.estado = response.data.uf;
                            pessoa.cidade = response.data.localidade.toUpperCase();
                            pessoa.bairro = response.data.bairro.toUpperCase();
                            pessoa.uf_viacep = response.data.uf;
                        }else{
                            pessoa.endereco = '';
                            pessoa.estado = '';
                            pessoa.cidade = '';
                            pessoa.bairro = '';
                            pessoa.uf_viacep = '';
                        }
            })
          }

        },




        recuperaTabelas() {

          this.loading = true;
          //busca a pessoa

          this.$API.get( '/api/cadastro/tabelas')
          .then(response => {

              this.planos = response.data.plano;                    
              this.nacionalidade = response.data.nacionalidade;
              this.estado_civil = response.data.estadoCivil; 
              this.escolaridade = response.data.escolaridade;
              this.tipoTempoServico = response.data.tipoTempoServico;
              this.parentesco = response.data.parentesco;
              this.paises = response.data.paises; 
    
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

        },




        recuperaPessoa() {
          
          this.loading = true;
          //busca a pessoa

          var params = {
              id_pessoa:  this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado].id_pessoa,
          }

          this.$API.post( '/api/cadastro/pessoa', params)
          .then(response => {

            if(response.data.campanha != null){
              this.protocolo = response.data.campanha.protocolo;
              this.ultima_atualizacao = response.data.pessoa.data_atualizacao;
            }

            
            //##################################
            //preenche os dados da pessoa
            //##################################
            this.participante = response.data.pessoa; 
            this.participante.data_nascimento = this.participante.data_nascimento.substr(0,10);
            this.participante.id_nacionalidade = parseInt(this.participante.id_nacionalidade);
            this.participante.id_estado_civil = parseInt(this.participante.id_estado_civil);
            this.participante.id_escolaridade = parseInt(this.participante.id_escolaridade);


            //this.participante.id_pais = parseInt(this.participante.id_pais);
            


            if(this.participante.id_pais == null){
              this.participante.id_pais = 31;
            }else{
              this.participante.id_pais = parseInt(this.participante.id_pais);
            }  


              
            //##################################
            //Preenche os planos do participante
            //##################################
              var qtd = response.data.participante.length;

              //preenche um array com os planos do participante 
              this.participante_planos = [];
              if(qtd > 0){
                for (let i = 0; i < qtd; i++) {
                  this.participante_planos.push({id_plano: parseInt(response.data.participante[i].id_plano)});
                }
              }
                
              
              //daqui pra baixo preenche os campos da tela com os planos
              
              if(qtd > 0){
                for (let i = 0; i < this.planos.length; i++) {
                  if(this.planos[i].id == response.data.participante[0].id_plano){
                      this.plano1 = this.planos[i].sigla;
                  }
                }
              }else{
                this.plano1 = '';
              } 

              if(qtd > 1){
                for (let i = 0; i < this.planos.length; i++) {
                  if(this.planos[i].id == response.data.participante[1].id_plano){
                      this.plano2 = this.planos[i].sigla;
                  }
                }
              }else{
                this.plano2 = '';
              }

              if(qtd > 2){
                for (let i = 0; i < this.planos.length; i++) {
                  if(this.planos[i].id == response.data.participante[2].id_plano){
                      this.plano3 = this.planos[i].sigla;
                  }
                }
              }else{
                this.plano3 = '';
              }

              
          //##################################
          //preenche as empresas
          //##################################
          
              this.empresas = response.data.tempo_servico;

              for (let i = 0; i < this.empresas.length; i++) {

                if(this.empresas[i].id == null){
                    this.empresas[i].id = i *-1;
                }

                this.empresas[i].data_admissao = this.empresas[i].data_admissao.substr(0,10);

                if(this.empresas[i].data_demissao != null){
                  this.empresas[i].data_demissao = this.empresas[i].data_demissao.substr(0,10);
                }  
                
                this.empresas[i].id_tipo_tempo_servico = parseInt(this.empresas[i].id_tipo_tempo_servico);
              } 



              
          //##################################
          //preenche os dados dos beneficiarios
          //##################################
          
              this.dependentes = response.data.dependentes;
              if(this.dependentes == null){
                this.dependentes = [];
              }
              for (let i = 0; i < this.dependentes.length; i++) {
                this.dependentes[i].pessoa_fisica.cpf = this.dependentes[i].pessoa_fisica.cpf.replace(/[^\d]+/g,'');	
                this.dependentes[i].id_parentesco = parseInt(this.dependentes[i].id_parentesco);
                this.dependentes[i].pessoa_fisica.data_nascimento = this.dependentes[i].pessoa_fisica.data_nascimento.substr(0,10);
                this.dependentes[i].pessoa_fisica.id_escolaridade = parseInt(this.dependentes[i].pessoa_fisica.id_escolaridade);
                
              } 
              
          //##################################
          //preenche os planos dos beneficiarios
          //##################################

              let planos_dependentes_aux = response.data.planodependentes;
              this.dependentes_planos = [];
              //Para cada beneficiário cria um registro de plano para cada plano do titular 
              for (let i = 0; i < this.dependentes.length; i++) {
                for (let x = 0; x < this.participante_planos.length; x++) {
                  let achou = false;
                  for (let j = 0; j < planos_dependentes_aux.length; j++) {
                    if( planos_dependentes_aux[j].id_plano == this.participante_planos[x].id_plano &&
                        planos_dependentes_aux[j].id_dependente ==  this.dependentes[i].id_dependente ){
                        this.dependentes_planos.push({
                                          id_plano: this.participante_planos[x].id_plano,
                                          id_dependente:  this.dependentes[i].id_dependente,
                                          beneficiario: planos_dependentes_aux[j].beneficiario,
                                          designado: planos_dependentes_aux[j].designado,
                                          percentual: planos_dependentes_aux[j].percentual,
                                          dt_ini: planos_dependentes_aux[j].dt_ini,
                                          erro: false});
                        achou = true;                  

                        }      
                    
                  }

                  if(!achou){
                    this.dependentes_planos.push({
                                          id_plano: this.participante_planos[x].id_plano,
                                          id_dependente:  this.dependentes[i].id_dependente,
                                          beneficiario: "N",
                                          designado: "N",
                                          percentual: "0",
                                          dt_ini: "",
                                          erro: false});
                  }
                  
                }  
              }
              
              
              
                              
          }) 
          .catch(error => {
                  //Erro ao recuperar os dados da pessoa
                  this.TrataErros(error);
          }); 

          
        },




        //#####################
        //funções utilitárias
        //####################
        idade(data_aniversario = '') {
          var d = new Date,
              ano_atual = d.getFullYear(),
              mes_atual = d.getMonth() + 1,
              dia_atual = d.getDate(),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_atual - ano_aniversario;

          if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        idadeNaAdmissao(data_aniversario = '', data_admissao) {

          var ano_admissao = data_admissao.substring(0,4),
              mes_admissao = data_admissao.substring(5,7),
              dia_admissao = data_admissao.substring(8,10),
              ano_aniversario = data_aniversario.substring(0,4),
              mes_aniversario = data_aniversario.substring(5,7),
              dia_aniversario = data_aniversario.substring(8,10),
              quantos_anos = ano_admissao - ano_aniversario;

          if (mes_admissao < mes_aniversario || mes_admissao == mes_aniversario && dia_admissao < dia_aniversario) {
              quantos_anos--;
          }
          quantos_anos = parseInt(quantos_anos);

          return quantos_anos < 0 ? 0 : quantos_anos;
        },


        validarCPF(cpf) {	
          cpf = cpf.replace(/[^\d]+/g,'');	
          if(cpf == '') return false;	
          // Elimina CPFs invalidos conhecidos	
          if (cpf.length != 11 || 
            cpf == "00000000000" || 
            cpf == "11111111111" || 
            cpf == "22222222222" || 
            cpf == "33333333333" || 
            cpf == "44444444444" || 
            cpf == "55555555555" || 
            cpf == "66666666666" || 
            cpf == "77777777777" || 
            cpf == "88888888888" || 
            cpf == "99999999999")
              return false;		
          // Valida 1o digito	
          let add = 0,
              i = 0,
              rev = 0;	

          for (i=0; i < 9; i ++)		
            add += parseInt(cpf.charAt(i)) * (10 - i);	
            rev = 11 - (add % 11);	
            if (rev == 10 || rev == 11)		
              rev = 0;	
            if (rev != parseInt(cpf.charAt(9)))		
              return false;		
          // Valida 2o digito	
          add = 0;	
          for (i = 0; i < 10; i ++)		
            add += parseInt(cpf.charAt(i)) * (11 - i);	
          rev = 11 - (add % 11);	
          if (rev == 10 || rev == 11)	
            rev = 0;	
          if (rev != parseInt(cpf.charAt(10)))
            return false;		
          return true;   
        },


        formatDate (date) {
          if (!date) return null

          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },

        
        addTeste() {
          this.teste = this.teste +1;
        },


        TrataErros(error){

          

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.simnao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if(typeof error.response === 'undefined'){
            console.log('Não retornou mensagem');
          }else{

            console.log(error.response);

            if('data' in error.response){
              if('mensagem' in error.response.data){
                if( error.response.data.mensagem == 'Token expirado'){
                  token_expirado = true;
                }  
              }

              if(token_expirado){
                this.mensagem = 'Sua sessão expirou. Faça um novo login.';
              } 
            }

            this.dialog = true;

            var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
            };

           // this.$store.commit('setusuariologado', usuario);
          }
          
        } 
      },
   }
</script>