import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary:   '#0b7eb3',
            secondary: '#00aeef',
            tertiary:  '#829fbd',
            anchor:    '#8c9eff',
            success:   '#008000',
            error:     '#c10101',
            table:     'eaeff7'
          },
        },
    },
});
