import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
//import router from './routes'

Vue.use(VueAxios, axios)

//###############################
// A função request é uma "closure"
// O objetivo dessa closure é guardar o token de acesso 
// em uma variável que não pode ser acessada fora dessa função
// e dessa forma proteger o token contra ataques de XSS
//###############################



function apipartic(){

  const meuaxios = axios;

  let token = {
    access_token: null,
    
  };

  let token_ged = {
    access_token: null,
    
  };

  let api =  process.env.VUE_APP_APIPARTIC;


  return {
    Iniciar: function(value){
      token = value;
    },
    setTokenGed: function(value){
      token_ged.access_token = value;
    },
    get: function(url){
      return  meuaxios({
        method: 'get',
        url: api+url,
        headers: { 'Authorization': 'Bearer '+ token.access_token },
        withCredentials: true
      });
    },
    post: function(url, data){
      return  meuaxios({
        method: 'post',
        url: api+url,
        data: data,
        headers: { 'Authorization': 'Bearer '+ token.access_token },
        withCredentials: true
      });
    },

  }
}

const APIpartic = apipartic();

export default APIpartic



