<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              :src="$store.state.urls.self+'/img/saco_dinheiro.png'"
            >
              
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo}}</v-toolbar-title>
              </v-toolbar>


              <v-card-text style="height:95%">
              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
                <v-row v-if="!loading && mensagem != ''">
                  <v-col cols="12" >
                    
                      <v-alert class="text-h5" text type="info" color="red darken-4" >{{mensagem}}</v-alert>
                    
                  </v-col>
                </v-row> 

                <v-row v-if="!loading && msg_ok != ''">
                  <v-col cols="12" >
                    
                      <v-alert class="text-h5" text type="info" color="green darken-4" >{{msg_ok}}</v-alert>
                    
                  </v-col>
                </v-row>

                <v-row v-if="!loading && mensagem == '' && msg_ok == '' ">
                  <v-col cols="12" >
                    
                      <v-alert  text type="info" color="blue darken-4" >
                      Prezado participante/assistido, <br>
                      <br>
                      Devido à tragédia ocorrida no estado do Rio Grande do Sul (RS), o Serpros disponibilizou a opção de suspensão excepcional e temporária do pagamento das parcelas de empréstimos para os participantes e assistidos que residem naquela região e que estejam adimplentes. <br>
                      <br>
                      Assim, você, participante ou assistido que esteja adimplente e que resida no RS, poderá suspender excepcionalmente o pagamento das próximas 3 (três) parcelas mensais de 2024 de seu contrato de empréstimo.  <br>
                      <br>
                      Estas parcelas suspensas terão seu vencimento prorrogado para o final do contrato, sem nenhum tipo de atualização do valor.  Caso o contrato esteja com menos de 3 (três) parcelas remanescentes, o vencimento das parcelas será prorrogado respeitando os mesmos 3 (três) meses de suspensão. <br>
                      <br>
                      Essa suspensão não altera os demais termos do contrato de empréstimo. <br>
                      <br> 
                      Para solicitar, basta clicar na opção "Desejo suspender" e em seguida no botão "salvar", e você receberá a confirmação da suspensão em seu e-mail cadastrado. <br>
                      <br>
                      O prazo para solicitar esta suspensão temporária e excepcional termina às 23:59 do dia 07/06/2024. <br>
                      <br>
                      Em caso de dúvidas, entre em contato com nossa Central de Relacionamento.</v-alert>
                    
                  </v-col>
                </v-row> 

                <v-form ref="form"  lazy-validation>  
                  <v-row v-if="!loading && mensagem == '' && msg_ok == ''">
                    <v-col cols="12" md="12">
                        <v-checkbox
                          v-model="checkbox"
                          label="Desejo suspender"
                          :rules="[...Rules.Required]"
                          :readonly="somente_leitura" 
                        ></v-checkbox>
                    </v-col>
                  </v-row> 
                </v-form>

                <v-row class="pb-0" v-if="!loading">
                  <v-col cols="12" md="2" class="pb-0" v-if="msg_ok == '' && mensagem == '' && !somente_leitura" > 
                    <v-btn  color="primary" rounded dark @click="confirmar" >Salvar</v-btn>
                  </v-col>
                  <v-col cols="12" md="2">
                      <v-btn color="tertiary" rounded dark @click="sair" >Voltar</v-btn>
                  </v-col>
                </v-row>                

              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ msg_dialog }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   import CompData from '@/components/CompData.vue';
   export default {
      components: {
          CompData,
      },
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo: "Suspender cobrança",
            form_valido: true,
            loading: true,
            mensagem: '',
            msg_ok: '',
            msg_dialog: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            parcelas: [],
            parcelas_selecionadas: [],
            data_vencimento: '',
            checkbox: false,
            somente_leitura: true,
         }
      },

      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo
        }

        this.$store.dispatch('logatendimento', params); 

        this.consultar();
      },
            
 
      methods: {

       
        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          this.salvar();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        consultar(){

          this.loading = true;

           var params = {
                iniciar: true
            }

          this.$API.post( '/api/suspender/iniciar', params)
          .then(response => {
            if(response.data.suspender == 'S'){
              this.msg_ok = 'Já existe uma solicitação de suspensão de cobrança cadastrada para o seu CPF.';
            }
            this.loading = false;  
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },


        confirmar(){

          if( this.$refs.form.validate() ){

              this.titulo = 'Salvar';
              this.msg_dialog = 'Confirma a suspensão da cobrança das parcelas?';
              this.sim_nao = true;
              this.cor_dialogo ="primary";
              this.dialog = true;
            
          }  

        },


        salvar(){

          this.loading = true;


            var params = {
                suspender: this.checkbox
            }

            this.$API.post( '/api/suspender/salvar', params)
            .then(response => {
              
              this.loading = false;  
              this.msg_ok = 'Solicitação cadastrada com sucesso!';

            })
            .catch(error => {
                this.TrataErros(error);
            });

        },




        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>