<template>
   <!-- Container principal da tela de login -->
   <v-container 
         fluid
         class="pa-0 ma-0"
   >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo da tela de login -->
          <v-card
            class="rounded-0"
          >
            <v-img
              min-height="600px"
              max-height="920px"
              src="img/escritorio.jpg"
            >
              <!-- Dentro da imagem existe um outro container para centralizar o card que contém o formulário de login -->
              <v-container 
                    fluid
                    fill-height

              >
                <!-- Essa v-row centraliza o card do formulário na vertical --> 
                <v-row
                  justify="center"
                >
                  <!-- Para telas pequenas usa toda a largura da tela e para telas medias usa somente sete colunas --> 
                  <v-col cols="12" md="7" >

                    <!-- Esse é o card branco que aparece no meio da tela --> 
                    <v-card
                      class="mx-auto rounded-xl"
                      elevation="12"
                    >
                      <v-row
                        justify="center"
                      >
                        <!-- O card branco tem duas colunas: uma com um card azul e outra com o form de login --> 
                        <v-col cols="12" md="6" class="d-none d-md-block ">
                          <v-container fill-height fluid>

                            <!-- Esse card azul --> 
                            <v-card 
                               dark 
                               class="primary mx-auto rounded-xl " 
                               min-height="360px"    
                               flat
                               
                            >
                              <v-card-title>Alterar E-mail</v-card-title>
                              <v-card-text>
                                <p class="text-body-1 text-justify">Digite seu CPF para verificar o e-mail cadastrado.</p>
                                <p class="text-body-1 text-justify">Caso deseje alterar o e-mail clique em alterar e informe data de nascimento e nome da mãe conforme consta em nosso cadastro.</p>
                                <p class="text-body-1 text-justify">Informe o novo endereço de e-mail e clique em avançar. Uma nova senha de acesso será enviada para o e-mail cadastrado.</p>
                                <p class="text-body-1 text-justify">Use seu CPF e a nova senha para entrar no sistema. Você pode cadastrar uma nova senha quando entrar no sistema. <router-link class="v-btn white--text" to="/login">Entrar</router-link>.</p>

                              </v-card-text>
                          
                            </v-card>
                          </v-container>   
                        </v-col>

                        <!-- Essa é a segunda coluna -->
                        <v-col cols="12" md="6">
                          <v-card-text>

                            <!-- Esse form de login -->
                            <v-form 
                              @submit.prevent="Entrar" 
                              ref="form"
                              v-model="valid"
                              lazy-validation
                            >
                              <v-img
                                  alt="Serpros Fundo Multipatrocinado"
                                  class="shrink"
                                  contain
                                  src="img/serpros-logo.png"
                                  height="60"
                              />

                              <br>

                              <v-text-field
                                  v-model="cpf"
                                  label="CPF"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                                  :readonly="etapa !=1"
                                  v-if="etapa < 4"
                              ></v-text-field>

                              <v-text-field
                                  v-model="email"
                                  label="E-mail atual"
                                  rounded
                                  outlined
                                  readonly
                                  filled
                                  v-if="etapa == 2"
                              ></v-text-field>

                            <!--  <v-text-field  
                                  v-model="data_nascimento" 
                                  label="Data de nascimento"  
                                  type="date" 
                                  outlined
                                  rounded
                                  :rules="Rules.Required" 
                                  v-if="etapa == 3"
                              ></v-text-field> -->

                              <comp-data  v-if="etapa == 3" v-model="data_nascimento" titulo="Data de nascimento" :outlined="true" :rounded="true"  :obrigatorio="true"/>

                            <!--  <v-menu  
                                v-if="etapa == 3"
                                offset-y 
                                :close-on-content-click="menu" 
                                content-class="elevation-0">
                                <template v-slot:activator="{ on }">
                                  <v-text-field 
                                     outlined 
                                     rounded 
                                     readonly
                                     v-on="on"
                                     :value="data_formatada" 
                                     label="Data de nascimento" 
                                     prepend_icon="date_range"
                                     @click="menu=false"
                                     >
                                     
                                  </v-text-field>   
                                </template>
                                <v-date-picker elevation="8" no-title flat locale="pt-BR" v-model="data_nascimento" @input="menu=true"></v-date-picker>
                              </v-menu> -->

                              <v-text-field
                                  v-model="nome_mae"
                                  label="Nome da Mãe"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                                  v-if="etapa == 3"
                              ></v-text-field>

                              <v-text-field
                                  v-model="email_novo"
                                  label="E-mail novo"
                                  rounded
                                  outlined
                                  :rules="Rules.Required"
                                  v-if="etapa == 3"
                              ></v-text-field>

                              <div class="ma-auto position-relative" style="max-width: 300px" v-if="etapa == 4">
                                <v-otp-input
                                  length="6"
                                  type="number"
                                  @finish="Confirmar"
                                  v-model="codigo_confirmacao"
                                  :disabled="loading"
                                ></v-otp-input>
                              </div>  
                              
                              <div class="text-center" v-if="etapa == 1">
                                  <v-btn  color="primary" @click= "verificaCPF" :disabled="!valid" rounded :loading="loading"> Avançar </v-btn>
                              </div>
                              <div class="text-center" v-if="etapa == 2">
                                  <v-btn  color="primary" @click= "etapa = 3;" :disabled="!valid" rounded :loading="loading"> Alterar E-mail </v-btn>
                              </div>
                              <div class="text-center" v-if="etapa == 3">
                                  <v-btn  color="primary" @click= "Confirmar" :disabled="!valid" rounded :loading="loading"> Enviar </v-btn>
                              </div>
                              
                              
                              <br>

                              <v-alert dense v-if="mostra_mensagem" v-model="mostra_mensagem"  text :type="tipo_mensagem"> {{mensagem}} </v-alert>

                              <br>

                              <div class="text-center" v-if="etapa==5">
                                <router-link
                                  to="/login"
                                  custom
                                  v-slot="{ navigate }"
                                >
                                  <v-btn @click="navigate" color="primary"  :disabled="!valid" rounded :loading="loading"> Avançar </v-btn>
                                </router-link>
                              </div>

                            </v-form>
                          </v-card-text>
                        </v-col>
                      </v-row>    
                    </v-card>
                  </v-col>
                </v-row> 
              </v-container>   
            </v-img>
          <!--  <v-footer
              padless
            >
              <v-card
                flat
                tile
                width="100%"
                height="200px"
                class="grey darken-4 text-center"
              >

                <v-card-text class="white--text">
                  {{ new Date().getFullYear() }} — <strong>Serpros Fundo Multipatrocinado</strong>
                </v-card-text>
              </v-card>
            </v-footer> -->
          </v-card>  
        </v-col>
      </v-row>  
   </v-container>

   
</template>

<script>
  import CompData from '@/components/CompData.vue';
  export default {
    
    components: {
            CompData,
    },
    data: function(){
      return {  
          active: null,
          indeterminate: null,
          loading: false,
          valid: false,
          Rules:{
            Required:[v => !!v || 'Obrigatório!'],   
          },
          cpf: null,
          data_nascimento: "",
          nome_mae: '',
          email: '',
          email_novo: '',
          mensagem: '',
          mostra_mensagem: false,
          tipo_mensagem: 'error',
          etapa: 1,
          codigo_confirmacao: '',
          menu: false,
      }
    },

    created: function () {

    },
    computed: {
      data_formatada() {
        if(this.data_nascimento == ""){
          return "  /  /    ";
        }else{
          return this.data_nascimento.substring(8,10)+"/"+this.data_nascimento.substring(5,7)+"/"+this.data_nascimento.substring(0,4);
        }
        
      }
    },
    methods:{
      
      verificaCPF() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,''),
              data_nascimento: this.data_nascimento
            };
            

            this.$API.post( '/api/alteraremail/verificacpf', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 2;
                  this.email = response.data.email;

                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Usuário ou senha inválidos';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },

      gerarCodigo() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,''),
              data_nascimento: this.data_nascimento,
              nome_mae: this.nome_mae,
            };

            this.$API.post( '/api/alteraremail/gerarcodigo', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 4;
                  this.tipo_mensagem = 'info';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;

                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Usuário ou senha inválidos';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },



      Confirmar() {
        if( this.$refs.form.validate() ){

            this.mostra_mensagem = false;
            this.loading = true;

            var params = { 
              cpf: this.cpf.replace(/[^\d]+/g,''),
              data_nascimento: this.data_nascimento,
              nome_mae: this.nome_mae,
              email_novo: this.email_novo,
            };

            this.$API.post( '/api/alteraremail/salvar', params)
            .then(response => {

                if(response.data.codigo =='ok'){
                  this.etapa = 5;
                  this.tipo_mensagem = 'success';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;

                }else{
                  this.tipo_mensagem = 'error';
                  this.mensagem = response.data.mensagem;
                  this.mostra_mensagem = true;
                }


                this.loading = false;

                           
            })
            .catch(error => {
              console.log(error.response);
              this.mensagem = 'Não foi possível alterar o e-mail';
              this.loading = false;
              this.mostra_mensagem = true;
              
            });

          
        }
      },

      



    }
    
     
  }
</script>