<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
              <v-card-text style="height:95%" v-if="somente_leitura">
                <v-row >
                  <v-col cols="12" >
                    <v-alert class="text-h5" text type="info" color="blue darken-4" >O sistema de requerimentos não pode ser iniciado no modo de atendimento</v-alert>
                  </v-col>
                </v-row> 
              </v-card-text>  
            


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>



<script>
   export default {

      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: 'Requerimentos',
            multiLine: true,
            absolute: false,
            form_valido: true,
            loading: true,
            exibe_alerta: false,
            alerta_color: 'green',
            alerta: '',
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            titulo: null,
            sim_nao: false,
            reverse: false,
            resposta: false,
            autorizado: false,
            somente_leitura: true,
            
            
         }
      },
      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params); 

        if(this.$store.state.alerta != ''){
            this.alerta = this.$store.state.alerta;
            this.$store.commit('setalerta','');
            this.alerta_color = "green";
            this.exibe_alerta = true;
        }

        if(!this.somente_leitura){
          this.chamaRequerimentos();
        }else{
          this.loading = false;
        }
        

      },
      methods: {
        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
                nome: '',
                id: '',
                email: '',
                matricula: '',
                id_setor: ''
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        },  

        perguntaSair(){

          this.titulo = 'Sair';
          this.mensagem = 'Deseja sair do sistema?';
          this.cor_dialogo = '#283593';
          this.sim_nao = true;
          this.dialog = true;

        },

        chamaRequerimentos(){

          this.loading = true;

          //let data = {cpf: this.$store.state.pessoa_fisica.cpf};
          //let data = {};

          this.$API.get( '/api/aut/requerimento')
          .then(response => {

            window.open( process.env.VUE_APP_URL_REQUERIMENTO+'?code='+response.data.codigo,'_blank');
            this.loading = false;
            this.$router.push('/');
    
          })
          .catch(error => {
              this.TrataErros(error);
          });

        },
        
        //#####################
        //funções utilitárias
        //####################


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 
      },
   }
</script>