<template>
  <div>
    
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
         }
      },

      created: function() {

        
        if(this.$route.params.tipo == "e"){
          this.$router.push('/simbnf/e');
        }else if(this.$route.params.tipo == "v"){
          this.$router.push('/simbnf/v');
        }else{
          this.$router.push('/simbnf/s');
        }
        
      },
   }
</script>