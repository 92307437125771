<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              :src="$store.state.urls.self+'/img/saco_dinheiro.png'"
            >
              
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo}}</v-toolbar-title>
              </v-toolbar>


              <v-card-text style="height:95%">
              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
              <v-form 
                ref="form"
                lazy-validation
              >

                <v-row v-if="!loading && mensagem != ''">
                  <v-col cols="12" >
                    
                      <v-alert class="text-h5" text type="info" color="red darken-4" >{{mensagem}}</v-alert>
                    
                  </v-col>
                </v-row> 

                <v-row v-if="!loading && msg_ok != ''">
                  <v-col cols="12" >
                    
                      <v-alert class="text-h5" text type="info" color="green darken-4" >{{msg_ok}}</v-alert>
                    
                  </v-col>
                </v-row>

                <v-row v-if="!loading && mensagem == '' && msg_ok == '' ">
                  <v-col cols="12" >
                    
                      <v-alert class="text-h5" text type="info" color="blue darken-4" >Selecione as parcelas que deseja pagar. Um boleto será gerado com o valor e a data de vencimento escolhidos por você. O boleto será enviado para o seu e-mail cadastrado. O envio do boleto não é imediato, pode demorar até um dia para chegar em sua caixa de correio. O valor das parcelas apresentado abaixo poderá sofrer correção conforme a data informada para o vencimento do boleto.</v-alert>
                    
                  </v-col>
                </v-row> 


                <v-row v-if="!loading && msg_ok == ''">
                  <v-col cols="12" md="3">
                      <comp-data  v-model="data_vencimento" titulo="Data de vencimento do boleto" :outlined="true" :rules="[obrigatorio, ...dataFutura(data_vencimento)]" />
                  </v-col>
                </v-row> 
              
  

                <v-row class="pb-0" v-if="!loading">
                  <v-col cols="12" md="3" class="pb-0" v-if="msg_ok == ''">
                    
                    <v-btn  color="primary" rounded dark @click="salvar" >Gerar Boleto</v-btn>
                    
                  </v-col>
                  <v-col cols="12" md="3"  >
                    
                      <v-btn color="tertiary" rounded dark @click="Voltar" >Voltar</v-btn>
                    
                  </v-col>
                </v-row> 


                <v-row v-if="!loading">
                  <v-col cols="12">
                    <v-data-table
                          :headers="headerParcelas"
                          :items="parcelas"
                          class="table"
                          no-data-text="Nenhuma parcela encontrada"
                          :items-per-page="-1"
                          :loading="loading.anexo"   
                          loading-text="Carregando parcelas..."
                          hide-default-footer
                          >
                          
                          <template v-slot:item.data_vencimento="{ item }">
                            <span>{{new Date(item.data_vencimento).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
                          </template>

                          <template v-slot:item.valor="{ item }">
                            <span>{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.valor) }}</span>
                          </template>
                          

                          <template v-slot:[`item.check`]="{ item }">
                              
                              <v-checkbox
                                v-model="parcelas_selecionadas"
                                :value="item.nr_seq_prest"
                                :disabled="desativaParcela(item.nr_seq_prest)"
                                
                              ></v-checkbox>
                              
                          </template>




                    </v-data-table>
                  </v-col>
                </v-row>


 
 
              </v-form> 
              

              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ msg_dialog }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   import CompData from '@/components/CompData.vue';
   export default {
      components: {
          CompData,
      },
      data: function(){
         return {
            obrigatorio: v => !!v || 'Obrigatório',
            titulo: "Exibir parcelas atrasadas",
            form_valido: true,
            loading: true,
            mensagem: '',
            msg_ok: '',
            msg_dialog: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            parcelas: [],
            parcelas_selecionadas: [],
            data_vencimento: '',
            headerParcelas: [
              {
                    text: '',
                    value: 'check',
                    sortable: false,
                    class: 'title white--text',
                    align: 'start',
                    width: 50,
              },
              {
                    text: 'Data de vencimento da parcela',
                    value: 'data_vencimento',
                    sortable: false,
                    width: 400,
                    class: 'title white--text'
                },
                {
                    text: 'Valor',
                    value: 'valor',
                    sortable: false,
                    
                    class: 'title white--text'
                }
                
            ],
         }
      },

      created: function() {

        let params = {
            api: this.$API,
            descricao: this.titulo
        }

        this.$store.dispatch('logatendimento', params); 

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        

        this.inicia();

        
      },
            
 
      methods: {

        dataFutura: function(valor) {

          let aprovado = true;
          let mensagem = '';

          let data_atual = new Date().toISOString();
          if (valor <= data_atual) {
            aprovado = false;
            mensagem = 'A data de vencimento deve ser maior que adata atual' ; 
          }

          return  aprovado || mensagem
        },

        desativaParcela: function(nr_seq_prest){


          if(this.parcelas[0].nr_seq_prest == nr_seq_prest){
            return false;
          }else{

            

            let indice = -1;
            let qtd = this.parcelas.length;
            for(let i=0; i<qtd; i++){

              if(this.parcelas[i].nr_seq_prest == nr_seq_prest){
                indice = i;
              }

            }

            
            if(this.parcelas_selecionadas.length >= indice){ 
              return false;
            }else{
              return true;
            }

          }


          
          

        },



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          this.gerarBoleto();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.msg_dialog = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  


        inicia(){

          this.loading = true; 
          this.mensagem = '';
          this.msg_ok = '';
          
          this.$API.get( '/api/emprestimo/parcelasatrasadas/'+this.$route.params.id_contrato_emp)
          .then(response => {
  
            this.mensagem = '';
            this.parcelas = response.data;
            
            this.loading = false;

          })
          .catch(error => {

              if(error.response.status == 412){
                  this.mensagem = error.response.data.mensagem;
              }

              this.loading = false;
          }); 

        },



        salvar(){

          if( this.$refs.form.validate() ){

            this.mensagem = '';
            let qtd = this.parcelas.length;
            let acabou = false;
            let tem_furo = false;
            for(let i=0; i<qtd; i++){
              if(this.parcelas_selecionadas.indexOf(this.parcelas[i].nr_seq_prest) == -1){
                acabou = true;
              }else{
                if(acabou){
                  tem_furo = true;
                }
              }
            }

            if(tem_furo){
              this.mensagem = 'Só é possível selecionar uma parcela se todas as parcelas anteriores também estiverem selecionadas.';
            }else{
              this.titulo = 'Salvar';
              this.msg_dialog = 'Confirma a geração do boleto?';
              this.sim_nao = true;
              this.cor_dialogo ="primary";
              this.dialog = true;
            }

            
          }  

        },

        gerarBoleto(){

          this.loading = true;

          var params = {
              cpf: this.$store.state.pessoa_fisica.cpf,
              data_vencimento: this.data_vencimento,
              tipo: 'ATR',
              numero_contrato: this.$route.params.nr_contrato,
              parcelas: this.parcelas_selecionadas,
              nome: this.$store.state.pessoa_fisica.nome
          }

          this.$API.post( '/api/boleto', params)
          .then(response => {
  
            this.mensagem = '';
            this.msg_ok = response.data.mensagem;
            this.loading = false;

          })
          .catch(error => {

              if(error.response.status == 412){
                  this.mensagem = error.response.data.mensagem;
              }

              this.loading = false;
          });

        },


        Voltar(){

         this.$router.push('/contratosinadimplentes');

        },

        
        
        //######################################
        //# permite o input de numeros inteiros apenas
        //###################################### 
        SomenteNumeros: function(evt) {
          this.valor_ok=false;
          this.msg_critica_valor='';

          evt = (evt) ? evt : window.event;
          let expect = evt.target.value.toString() + evt.key.toString();
          
          if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
            evt.preventDefault();
          } else {
            return true;
          }
        },



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>