<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/paiefilho.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 

              <v-row v-if="!loading && !pode_simular">
                <v-col cols="12" >
                  <v-card-text>
                    <v-alert class="text-h5" text type="info" color="blue darken-4" >{{mensagem}}</v-alert>
                  </v-card-text>
                </v-col>
              </v-row> 


            <v-form ref="form" v-model="form_valido" lazy-validation> 
              <!-- 
              ####################################################
              # Painel de entrada do simulador simplificado
              ####################################################
              -->
        
              <v-row v-if="!loading && pode_simular">
                <v-col cols="12" md="9" >
                  <v-card-text>     
                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">Salário:</div>
                      </v-col>
                      <v-col cols="12"  md="5">
                        <div class="text-h4 font-weight-light ml-4 text-right" v-text="Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(remuneracao)" ></div>
                      </v-col>
                    </v-row>    
                    
                    
                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">Idade Base:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h4 font-weight-light ml-4 text-right" v-text="idade" ></div>
                      </v-col>
                    </v-row> 


                    <v-row>
                      <v-col cols="12" md="7">
                        <div class="text-h5 font-weight-light">Referência:</div>
                      </v-col>
                      <v-col cols="12" md="5">
                        <div class="text-h4 font-weight-light ml-4 text-right" v-text=" data_referencia.substr(5,2)+'/'+data_referencia.substr(0,4)" ></div>
                      </v-col>
                    </v-row> 

                    
                  </v-card-text>

                  <v-card-text>     
                    <v-data-table
                      :headers="headerContribuicoes"
                      :items="contribuicoes"
                      class="table"
                      no-data-text="Não há nenhuma contribuição"
                      :items-per-page="-1"
                      hide-default-footer
                      >

                      <template v-slot:item.valor="{ item }">
                        <span v-if="item.tipo == 1">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_risco) }}</span>
                        <span v-if="item.tipo == 2">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_basica) }}</span>
                        <span v-if="item.tipo == 3">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_variavel) }}</span>
                        <span v-if="item.tipo == 4">
                          <v-text-field
                            class="mt-4"
                            outlined
                            dense
                            v-model="contribuicao_espontanea"
                            type="number"
                            hide-spin-buttons
                            :rules="[...maxEspontanea]"
                            reverse
                            prefix=",00"
                            suffix="R$"
                          ></v-text-field>
                        </span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea !=''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_risco + contribuicao_basica + contribuicao_variavel + parseFloat(contribuicao_espontanea)) }}</span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea ==''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_risco + contribuicao_basica + contribuicao_variavel ) }}</span>
                        <span v-if="item.tipo == 6">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_risco + contribuicao_basica + contribuicao_variavel) }}</span>
                        <span v-if="item.tipo == 7">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(((contribuicao_risco + contribuicao_basica + contribuicao_variavel)*2) + parseFloat(contribuicao_espontanea)) }}</span>
                        
                      </template>

                      <template v-slot:item.percentual="{ item }">
                        <v-select
                          class="mt-4"
                          v-if="item.tipo == 3"
                          outlined
                          dense
                          v-model="percentual_variavel"
                          :items="percentuais"
                          @change="contribuicao_variavel = calculaVariavel(remuneracao, vrs, percentual_variavel* 10)"
                          ></v-select>
                      </template>

                      <template v-slot:item.adm="{ item }">
                        <span v-if="item.tipo == 1">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_risco * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 2">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_basica * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 3">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_variavel * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 4">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_espontanea * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea !=''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel + parseFloat(contribuicao_espontanea)) * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea ==''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 6">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * taxa_carregamento) }}</span>
                        <span v-if="item.tipo == 7">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * 2) + parseFloat(contribuicao_espontanea)) * taxa_carregamento) }}</span>
                      </template>

                      <template v-slot:item.liquido="{ item }">
                        <span v-if="item.tipo == 1">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(0) }}</span>
                        <span v-if="item.tipo == 2">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_basica - (contribuicao_basica * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 3">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_variavel - (contribuicao_variavel * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 4">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(contribuicao_espontanea - (contribuicao_espontanea * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea !=''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel + parseFloat(contribuicao_espontanea)) - ((contribuicao_risco + contribuicao_basica + contribuicao_variavel + parseFloat(contribuicao_espontanea)) * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 5 && contribuicao_espontanea ==''">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel) - ((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 6">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format((contribuicao_risco + contribuicao_basica + contribuicao_variavel) - ((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * taxa_carregamento)) }}</span>
                        <span v-if="item.tipo == 7">{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(((contribuicao_risco + contribuicao_basica + contribuicao_variavel)*2 + parseFloat(contribuicao_espontanea) ) - ((((contribuicao_risco + contribuicao_basica + contribuicao_variavel) * 2) + parseFloat(contribuicao_espontanea)) * taxa_carregamento)) }}</span>
                      </template>

                    </v-data-table>
                  </v-card-text>
                </v-col>
                
                
                
                <!-- 
                ####################################################
                # Painel de aviso
                ####################################################
                -->

                <v-col cols="12" md="3">
                  <v-card 
                    class="mt-4 mr-4 cardTransparente"
                    outlined
                  >
                    <v-card-text>     

                      <v-row>
                        <v-col cols="12" md="12">

                          <p class="text-body-1 text-justify">O valor da contribuição patronal é o somatório das contribuições normal risco, normal básica e normal variável.</p>
                          <p class="text-body-1 text-justify">No entanto, o valor da contribuição administrativa, bem como o líquido revertido para o saldo de contas, corresponde ao somatório das contribuições normal básica, normal variável e espontânea mensal.</p>

                        </v-col>
                        
                      </v-row> 
                      
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>   
            </v-form>


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: "Simulador de Autopatrocínio",
            form_valido: true,
            loading: true,
            loading_fator: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            idade: 0,
            contribuicao_participante: 0,
            remuneracao: 0,
            contribuicao_risco: 0,
            contribuicao_basica: 0,
            contribuicao_variavel: 0,
            contribuicao_espontanea: 0,
            contribuicao_esporadica: 0,
            percentual_variavel:0,
            max_espontanea: 0,
            taxa_carregamento: 0,
            vrs: 0,
            percentuais: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            data_referencia: '',
            valor_espontanea: 0,
            plano: null,
            podeSimular: false,
            tipo_tributacao: "",
            contribuicoes: [],
            headerContribuicoes: [
                {
                    text: 'Contribuições',
                    align: 'start',
                    sortable: false,
                    value: 'descricao',
                    class: 'title white--text text-body-1'
                },
                {
                    text: 'Valor',
                    sortable: false,
                    align: 'end',
                    value: 'valor',
                    width: 180,
                    class: 'title white--text text-body-1'
                },
                {
                    text: 'Percentual',
                    align: 'center',
                    sortable: false,
                    value: 'percentual',
                    width: 100,
                    class: 'title white--text text-body-1'
                },
                {
                    text: 'Contribuição Administrativas',
                    align: 'end',
                    sortable: false,
                    value: 'adm',
                    width: 100,
                    class: 'title white--text text-body-1'
                },
                {
                    text: 'Líquido Revertido para Saldo Conta',
                    value: 'liquido',
                    align: 'end',
                    sortable: false,
                    class: 'title white--text text-body-1'
                },
            ],

         }
      },
      created: function() {
        
        let params = {
          api: this.$API,
          descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params); 

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.iniciaSimulador();

        
      },
            

 
      methods: {

        maxEspontanea: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(valor > this.max_espontanea){
            aprovado = false;
            mensagem = 'Máximo: '+  (Math.floor(this.max_espontanea * 100) / 100).toFixed(2) ;
          }

          return  aprovado || mensagem
        },


        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          var usuario = {
                logado: false,
              };

          this.$store.commit('setusuariologado', usuario);

          this.$router.push('/login');
         

        },  

        //######################################
        //# Funcoes genéricas
        //###################################### 

        zeraVariavel(){
           this.valor_variavel = 0;
        },

        //######################################
        //# Inicia o simulador
        //###################################### 

        iniciaSimulador(){

          //var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];

          var plano = null;

          for(let i=0, len=this.$store.state.planos_usuario.length; i<len; i++){
            if(this.$store.state.planos_usuario[i].id_plano == 2 && (this.$store.state.planos_usuario[i].situacao =="ATIVO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="BPD" || 
                                                                     this.$store.state.planos_usuario[i].situacao =="AUTOPATROCÍNIO" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="AUXÍLIO DOENÇA" ||
                                                                     this.$store.state.planos_usuario[i].situacao =="ACIDENTE DE TRABALHO")){
              
              plano = this.$store.state.planos_usuario[i];

            }
          }

          if(plano != null){

            this.pode_simular = true;

            this.tipo_tributacao = plano.tipo_tributacao;

            var params = {
              id_pessoa: plano.id_pessoa,
              data_nascimento: this.$store.state.pessoa_fisica.data_nascimento,
              id_pessoa_fisica: this.$store.state.pessoa_fisica.id,
              tipo_tributacao: plano.tipo_tributacao
            };

            this.$API.post( '/api/simulador/preparaps2', params)
            .then(response => {

                this.idade = response.data.idade;
                this.remuneracao = response.data.salario;
                this.percentual_variavel = parseInt(response.data.percentual_variavel);
                this.vrs = parseFloat(response.data.vrs);
                this.max_espontanea = this.remuneracao * 0.15;
                this.max_espontanea = parseFloat(this.max_espontanea.toFixed(2));

                this.contribuicao_basica =  this.calculaBasica(this.remuneracao);
                this.contribuicao_variavel = this.calculaVariavel(this.remuneracao, this.vrs, this.percentual_variavel* 10);
                //this.contribuicao_espontanea = this.calculaEspontanea(this.remuneracao, 0);
                this.data_referencia = response.data.data_referencia;  
                this.contribuicao_espontanea = Math.trunc(response.data.valor_espontanea);   

                this.contribuicao_risco = response.data.contribuicao_risco;
                //this.contribuicao_risco = 0;

                //console.log(parseFloat(this.contribuicao_espontanea));

                
                this.contribuicoes.push({
                  tipo: 1,
                  descricao: 'CONTRIBUIÇÃO NORMAL DE RISCO',
                  valor: this.contribuicao_risco,
                  percentual: '',
                  adm: '',
                  liquido: '',
                });

                this.contribuicoes.push({
                  tipo: 2,
                  descricao: 'CONTRIBUIÇÃO NORMAL BÁSICA',
                  valor: this.contribuicao_basica,
                  percentual: '',
                  adm: '',
                  liquido: '',
                });


                this.contribuicoes.push({
                  tipo: 3,
                  descricao: 'CONTRIBUIÇÃO NORMAL VARIÁVEL',
                  valor: this.contribuicao_variavel,
                  percentual: response.data.percentual_variavel + '%',
                  adm: '',
                  liquido: '',
                });

                this.contribuicoes.push({
                  tipo: 4,
                  descricao: 'CONTRIBUIÇÃO ESPONTÂNEA MENSAL',
                  valor: this.contribuicao_espontanea,
                  percentual: '',
                  adm: '',
                  liquido: '',
                });

                this.contribuicoes.push({
                  tipo: 5,
                  descricao: 'CONTRIBUIÇÃO TOTAL DO PARTICIPANTE',
                  valor: this.contribuicao_risco + this.contribuicao_basica + this.contribuicao_variavel + parseFloat(this.contribuicao_espontanea),
                  percentual: '',
                  adm: '',
                  liquido: '',
                });

                this.contribuicoes.push({
                  tipo: 6,
                  descricao: 'CONTRIBUIÇÃO TOTAL DO PATROCINADOR',
                  valor: this.contribuicao_risco + this.contribuicao_basica + this.contribuicao_variavel,
                  percentual: '',
                  adm: '',
                  liquido: '',
                });

                this.contribuicoes.push({
                  tipo: 7,
                  descricao: 'CONTRIBUIÇÃO TOTAL',
                  valor: ((this.contribuicao_risco + this.contribuicao_basica + this.contribuicao_variavel) * 2) + parseFloat(this.contribuicao_espontanea),
                  percentual: '',
                  adm: '',
                  liquido: '',
                });

                this.loading = false;
                
            })
            .catch(error => {
                this.TrataErros(error);
            });
          }else{
            this.pode_simular = false;
            this.mensagem = "A Simulação de Autopatrocínio não está disponível para você.";
            this.loading = false;
          }
        },

        //######################################
        //# Calculo das contribuicoes
        //###################################### 


        calculaVariavel(remuneracao, vrs, percentual){
            
            if(remuneracao > (vrs * 8)){
              var aux = (remuneracao - (vrs * 8)) * (percentual/1000);
              return Math.round(aux * 100)/100;
            }else{
              return 0;
            }
        },

        calculaBasica(remuneracao){
            return remuneracao * 0.01;  
        },

        calculaEspontanea(remuneracao, percentual){
            var aux =  remuneracao * percentual/1000;
            return  Math.round(aux * 100)/100;
        },

        calculaAdm(contribuicao){
            return contribuicao * 0; 
        },

        calculaContribTotal(salario){

            var contrib_basica = 0;
            var contrib_variavel = 0;

            contrib_basica = this.calculaBasica(salario);
            contrib_variavel = this.calculaVariavel(salario, this.vrs, this.percentual); 
            
            return contrib_basica + contrib_variavel - this.calculaAdm(contrib_basica + contrib_variavel);
        },


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>