<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="2000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo}}</v-toolbar-title>
              </v-toolbar>


              <v-card-text style="height:95%">
              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
              <v-form 
                ref="form"
                lazy-validation
              >
              
                <v-row v-if="!loading">
                  <v-col cols="12" md="3" >
                    <v-text-field
                        v-model="senha_atual"
                        label="Senha atual"
                        type="password"
                        outlined
                        dense
                        :rules="[...Rules.Required]"
                        :readonly="somente_leitura" 
                    ></v-text-field>
                    
                  </v-col>
                </v-row>   

                <v-row v-if="!loading">
                  <v-col cols="12" md="3" >
                    <v-text-field
                        v-model="senha_nova"
                        label="Senha nova"
                        type="password"
                        outlined
                        dense
                        hint="A senha deve ter 8 caracteres, somente números."
                        :rules="[...Rules.Required, ...tamanho, ...SomenteNumeros, ...validaSenha]"
                        :readonly="somente_leitura" 
                    ></v-text-field>
                    
                  </v-col>
                </v-row>

                <v-row v-if="!loading">
                  <v-col cols="12" md="3" >
                    <v-text-field
                        v-model="repetir_senha_nova"
                        label="Repetir senha nova"
                        type="password"
                        outlined
                        dense
                        hint="A senha deve ter 8 caracteres, somente números."
                        :rules="[...Rules.Required, ...tamanho, ...SomenteNumeros, ...validaSenha]"
                        :readonly="somente_leitura" 
                    ></v-text-field>
                    
                  </v-col>
                </v-row>
 
              </v-form>


              <v-row v-if="!loading">
                <v-col cols="12" md="1"  v-if="!somente_leitura">
                  
                    <v-btn  color="primary" rounded dark @click="executar" >Salvar</v-btn>
                  
                </v-col>
                <v-col cols="12" md="1"  >
                  
                    <v-btn color="tertiary" rounded dark @click="Voltar" >Voltar</v-btn>
                  
                </v-col>
              </v-row>  

              

              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
              </v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo: "Trocar Senha",
            form_valido: true,
            loading: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            senha_atual: '',
            senha_nova: '',
            repetir_senha_nova: '',
            somente_leitura: true,
         }
      },

      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo
        }

        this.$store.dispatch('logatendimento', params);

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        

        //this.inicia();

        
      },
            
 
      methods: {

        validaSenha: function(valor) {
          
          let aprovado = true;
          let mensagem = '';

          if(this.senha_nova != '' && this.repetir_senha_nova != ''){
            if(this.senha_nova != this.repetir_senha_nova ){
              aprovado = false;
              mensagem = 'Senhas diferentes' ; 
            }
          }
          

          return  aprovado || mensagem
        },

        SomenteNumeros: function(valor) {

          let aprovado = true;
          let mensagem = '';


          if (!/^[0-9]*$/.test(valor)) {
            aprovado = false;
            mensagem = 'Somente números' ; 
          }

          return  aprovado || mensagem
        },

        tamanho: function(valor) {

          let aprovado = true;
          let mensagem = '';

  

          if (valor.length != 8) {
            aprovado = false;
            mensagem = 'A senha deve ter 8 caracteres, somente números' ; 
          }

          return  aprovado || mensagem
        },

        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 

        BtnSim(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          this.sair();
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        

        
        
        
        executar(){

          if( this.$refs.form.validate() ){

            this.loading = true;


            var params = {
                senha_nova: this.senha_nova,
                senha_atual: this.senha_atual
            }

            

            this.$API.post( '/api/trocarsenha', params)
            .then(response => {

              if(response.data.codigo =='ok'){
                this.alerta_color = 'green';                
              }else{
                this.alerta_color = '#B00020';
              }
              
              this.loading = false;  
              this.alerta = response.data.mensagem;
              this.exibe_alerta = true;

                
                         

            })
            .catch(error => {
                this.loading = false;
            });

          }

          
          

        },


        Voltar(){

         this.$router.push('/');

        },
        



        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>