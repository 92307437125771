<template>
  <div>
    <v-snackbar
    v-model="exibe_alerta"
    timeout="4000"
    :color="alerta_color"
    >
    {{ alerta }}
    </v-snackbar> 

    <v-container fluid >
      <v-row >
        <v-col >
          <!-- Esse card é a imagem de fundo -->
          <v-card
            class="rounded-0"
            flat
          >
            <v-img
              src="img/saco_dinheiro.png"
            >
              <v-toolbar flat dense tile >
                <v-app-bar-nav-icon class="d-lg-none ml-n6" @click.stop="$store.commit('switchSidebar');"></v-app-bar-nav-icon>
                <v-toolbar-title class="text-h5 ml-n5"  >{{titulo_pagina}}</v-toolbar-title>
              </v-toolbar>


              <v-row v-if="loading" class="mt-5">
                <v-col>
                  <div class="text-center" >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </v-col>
              </v-row>  
              <v-row v-if="loading" >
                <v-col>
                  <div class="text-center" >Carregando</div>
                </v-col>
              </v-row> 
            
       
              <v-row v-if="!loading && !exibeDocumento">
                <v-col cols="12" md="12" >
                  <v-card-text>     

                    <v-row >
                      <v-col cols="12">
                        <v-data-table
                          :headers="headerContratos"
                          :items="contratos"
                          class="table"
                          no-data-text="Não existem contratos."
                          :items-per-page="-1"
                          :loading="loading.anexo"   
                          loading-text="Carregando contratos..."
                          hide-default-footer
                          >
                          
                          <template v-slot:item.dt_credito="{ item }">
                            <span>{{new Date(item.dt_credito).toLocaleString("pt-BR",{dateStyle: "short"})}}</span>
                          </template>

                          <template v-slot:item.vl_pmt="{ item }">
                            <span>{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.vl_pmt) }}</span>
                          </template>

                          <template v-slot:item.saldo="{ item }">
                            <span>{{ Intl.NumberFormat('pt-BR',{style:'currency', currency:'BRL', minimumFractionDigits: 2}).format(item.saldo) }}</span>
                          </template>

                          

                          <template v-slot:[`item.actions`]="{ item }">
                              
                              <v-btn v-if="item.ic_controle_judicial == 'N'" class="no-uppercase" text @click="exibecontrato(item.id_contrato_emp,item.nr_contrato)">Exibir parcelas em aberto</v-btn>
                              
                          </template>




                        </v-data-table>
                      </v-col>
                    </v-row> 

                  </v-card-text>
                </v-col>
                
              </v-row>   

              <v-row v-if="!loading && exibeDocumento">
                <v-col cols="12" md="12" class="text-right">

                   <v-card-text >
                    <v-btn color="primary" rounded dark @click="exibeDocumento=false;fileURL=null;" >Voltar</v-btn>
                  </v-card-text>
                  
                </v-col>
              </v-row> 

              <v-row v-if="!loading && exibeDocumento" class="fill-height mt-n5" >
                <v-col cols="12" md="12" >
                    <v-card-text style="height:98%">     
                      <iframe  :src="'data:Application/pdf;base64,'+ fileURL" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>
                    </v-card-text>
                </v-col>
                
              </v-row> 
            


              <v-overlay
                absolute
                :value="dialog"
              >
              </v-overlay>

              <v-snackbar
                v-model="dialog"
                multi-line
                centered
                :color="cor_dialogo"
                timeout="-1"
                max-width="400"
                min-height="140"
                vertical
              >
                {{ mensagem }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnOk(titulo)"
                    v-if="!sim_nao"
                  >
                    Ok
                  </v-btn>
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnSim(titulo)"
                    v-if="sim_nao"
                  >
                    Sim
                  </v-btn>
                          <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="BtnNao(titulo)"
                    v-if="sim_nao"
                  >
                    Não
                  </v-btn>
                </template>
              </v-snackbar> 
            </v-img>
          </v-card>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
   export default {
      data: function(){
         return {
            Rules:{
              Required: [v => !!v || 'Obrigatório',]
            },
            titulo_pagina: "Contratos Ativos",
            titulo: '',
            digitar_valor: false,
            form_valido: true,
            loading: true,
            loading2: true,
            loading_fator: false,
            mensagem: '',
            dialog: false,
            cor_dialogo: '#B00020',
            exibe_alerta: false,
            alerta: '',
            alerta_color: "green",
            sim_nao: false,
            contratos: [],
            fileURL: null,
            exibeDocumento: false,
            id_contrato_cancelar: null,
            somente_leitura: true,
            headerContratos: [
                {
                    text: 'Contrato',
                    align: 'start',
                    sortable: false,
                    value: 'nr_contrato',
                    width: 10,
                    class: 'title white--text'
                },
                {
                    text: 'Situação',
                    value: 'nm_sit_contrato',
                    sortable: false,
                    width: 20,
                    class: 'title white--text'
                },
                {
                    text: 'Data do crédito',
                    value: 'dt_credito',
                    sortable: false,
                    width: 180,
                    class: 'title white--text'
                },
                {
                    text: 'Prazo',
                    value: 'nr_prazo',
                    sortable: false,
                    width: 10,
                    class: 'title white--text'
                },
                {
                    text: 'Parcela',
                    value: 'vl_pmt',
                    sortable: false,
                    width: 140,
                    align: 'right',
                    class: 'title white--text'
                },
                {
                    text: 'Saldo',
                    value: 'saldo',
                    sortable: false,
                    width: 140,
                    align: 'right',
                    class: 'title white--text'
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'end',
                    sortable: false,
                   
                    class: 'title white--text'
                },
            ],
         }
      },

      created: function() {

        if(this.$store.state.atendente == null){
          this.somente_leitura = false;
        }

        let params = {
            api: this.$API,
            descricao: this.titulo_pagina
        }

        this.$store.dispatch('logatendimento', params);

        var texto = '';
        this.$store.dispatch('exibeajuda',texto);

        if(this.$store.state.alerta != ''){
           this.alerta = this.$store.state.alerta;
           this.$store.commit('setalerta','');
           this.exibe_alerta = true;
        }

        this.inicia();

        
      },
            
 
      methods: {



        //#####################
        //click dos botões da tela
        //####################
        
        BtnOk(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
          
           
        }, 

        BtnSim(){

          if(this.titulo == 'cancelar_contrato'){
            this.cancelar(this.id_contrato_cancelar);
          }

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
           
        }, 


        BtnNao(){

          this.dialog = false;
          this.titulo = '';
          this.mensagem = '';
             
          
        }, 


        sair(){

          this.$router.push('/');        

        },  

        

        //######################################
        //# Busca os contratos do participante
        //###################################### 

        inicia(){

          this.contratos = [];

          

          //var plano = this.$store.state.planos_usuario[this.$store.state.index_plano_selecionado];
            

            this.erro_autorizacao_sisgcs = false; 
            this.loading = true;
            var x =this.$store.state.planos_usuario.length;

            var pessoas = [];
            var pessoa_aux = null;
            for(var i=0; i < x ; i++){

                if( !pessoas.includes(this.$store.state.planos_usuario[i].id_pessoa) ){
                   pessoas.push(this.$store.state.planos_usuario[i].id_pessoa);
                }
            }

            x = pessoas.length;

            for(var i=0; i < x ; i++){

              var params = {
                id_pessoa: pessoas[i]
              }

              this.$API.post( '/api/emprestimo/contratosativos', params)
              .then(response => {
                  
                  if(response.data.contratos.length > 0){
                    response.data.contratos.forEach(contrato => {
                      if(contrato.id_sit_contrato == "4" || contrato.id_sit_contrato == "5"){
                        contrato.saldo = 0;
                      }
                      this.contratos.push(contrato);

                    });
                  }

                  if(i == x ){
                    this.loading = false;
                  }

                  
                  

              });
              
            }

            

            

        },

        
        exibecontrato(id_contrato_emp, nr_contrato){

          this.$router.push('parcelasantecipadas/'+id_contrato_emp+'/'+nr_contrato);

        },


        TrataErros(error){

          console.log(error.response);

          this.cor_dialogo = '#B00020';
          this.loading = false;
          this.sim_nao = false;
          this.titulo = 'Alerta';
          this.mensagem = 'Ocorreu um problema, o sistema será finalizado.';
          

          var token_expirado = false;
          if('data' in error.response){
            if('mensagem' in error.response.data){
              if( error.response.data.mensagem == 'Token expirado'){
                token_expirado = true;
              }  
            }

            if(token_expirado){
              this.mensagem = 'Sua sessão expirou. Faça um novo login.';
            } 
          }

          this.dialog = true;

          var usuario = {
              logado: false,
              nome: '',
              id: '',
              email: '',
              matricula: '',
              id_setor: ''
            };

            this.$store.commit('setusuariologado', usuario);
        } 

      },
   }
</script>